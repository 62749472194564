import {Component, Inject, Input, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-warning-dialog',
  templateUrl: './warning-dialog.component.html',
  styleUrls: ['./warning-dialog.component.scss'],
})
export class WarningDialogComponent implements OnInit {
  constructor(
    private _router: Router,
    private dialogRef: MatDialogRef<WarningDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {}

  confirmDialog() {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('serviceId');
    if (this.data.usercase.status == 'modify') {
      this._router.navigate([`/casetype/${this.data.usercase.case_id}`],
      {queryParams: {serviceId: myParam}});
    } else {
      this._router.navigate([`/cases/details/${this.data.usercase.id}`],
      {queryParams: {serviceId: myParam}});
    }
    this.dialogRef.close();
  }
}
