export const translation: any = {
  'User already has a draft of this type.':
    'لديك بالفعل مسودة محفوظة من هذه القضية',
  'User already has a case of this type.': 'لديك بالفعل قضية من هذا النوع',
  'User already has an active inquiry.': 'لديك بالفعل استشارة جارية ',
  'Invalid OTP or login data': 'هذا الرمز غير صحيح',
  'User token not found.': 'هذا الرمز غير صحيح',
  "email dosn't exist.": 'البيانات المدخلة غير صحيحة',
  'Email already exist.': 'هذاالبريد الالكترونى مسجل بالفعل',
  'User already have a cancellation request': 'لقد قدمت طلب تنازل مسبقًا',
  'User already have a complain complain': 'لقد قدمت شكوى لهذه القضية مسبقًا',
  'Please verify your account first': 'من فضلك فعل الحساب الخاص بك',
  'Verify token expired': 'هذا الرمز غير صحيح. اعد ارسال الرمز مرة اخرى',
  'National ID already exist.': ' رقم الهوية / الإقامة  هذا مسجل بالفعل',
  "phone dosn't exist.": 'رقم الهاتف هذا غير صحيح',
  'Phone already exist.': 'رقم الهاتف هذا مسجل بالفعل',
  'OTP has been expired': 'هذا الرمز قد انتهت صلاحيته',
  'You already have a processing subscription for this service.': 'لديك اشتراك جاري في هذه الخدمة بالفعل'
};
