import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { PaginatorSetting } from "src/app/core/interfaces/general.interface";
import { PaginationService } from "./pagination.service";

@Component({
  selector: "app-pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.scss"],
})
export class PaginationComponent implements OnInit {
  @Input() width: string = "100";
  @Input() pageSize: number = 5;
  paginatorSetting: PaginatorSetting = {
    length: 0,
    pageSize: 5,
    pages: 1,
    skip: 0,
  };
  @Output() pageChange = new EventEmitter<object>();
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  constructor(private _pagination: PaginationService) {}

  ngOnInit(): void {
    this._pagination.getEvent().subscribe((data) => {
      this.paginatorSetting.length = data;
      let totalPages = Math.ceil(this.paginatorSetting.length / this.pageSize);
      this.paginatorSetting.pages = totalPages <= 0 ? 1 : totalPages;
    });
  }

  //when click in prev or net button
  onPageButton(event: PageEvent): void {
    let totalPages = Math.ceil(event.length / event.pageSize);
    this.paginatorSetting.pages = totalPages <= 0 ? 1 : totalPages;
    this.onPageChange();
  }

  //when click in number button
  onPageNum(i: number): void {
    this.paginator.pageIndex = i;
    this.onPageChange();
  }

  //get data of this page
  onPageChange(): void {
    this.pageChange.emit({
      size: this.pageSize,
      index: this.paginator.pageIndex * this.pageSize,
    });
  }
}
