import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {ServicesListService} from 'src/app/case-type/services/services-list.service';
import {MainService} from 'src/app/core/interfaces/main-service';
import {PaymentService} from 'src/app/payment/service/payment.service';
import {MessageDialogComponent} from 'src/app/shared/dialogs/message-dialog/message-dialog.component';
import {FooterService} from 'src/app/ui/service/footer.service';
import {NavbarService} from 'src/app/ui/service/navbar.service';

@Component({
  selector: 'app-service-plans-page',
  templateUrl: './service-plans-page.component.html',
  styleUrls: ['./service-plans-page.component.scss'],
})
export class ServicePlansPageComponent implements OnInit {
  mainServices: MainService[] | null = null;
  pageService!: MainService | undefined;
  serviceIdParam!: string;
  servicesListSub$!: Subscription;
  subRoutSub$!: Subscription;
  paymentSub$!: Subscription;
  routeSub$!: Subscription;
  isloading = false;
  spinnerShow: boolean = false;

  constructor(
    private _navbar: NavbarService,
    private _footer: FooterService,
    private _router: Router,
    private _ServicesListService: ServicesListService,
    private _activeRoute: ActivatedRoute,
    private _paymentService: PaymentService,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
  ) {
    //set navbar to visible
    this._navbar.setNavbarVisiabilty(true);
    this._footer.setFooterVisibility(true);

    this.routeSub$ = this._activeRoute.params.subscribe((params) => {
      this.serviceIdParam = params['serviceId'];
    });

    //get all main services
    this.isloading = true;
    this.servicesListSub$ = this._ServicesListService
      .getMainServices()
      .subscribe({
        next: (data) => {
          this.mainServices = data;
          this.isloading = false;
        },
        complete: () => {
          if (this.mainServices && this.serviceIdParam) {
            this.pageService = this.mainServices.find(
              (item) => item.id === +this.serviceIdParam,
            );
            this.addPayment();
          }
        },
      });
  }

  ngOnInit(): void {}

  addPayment() {
    this.subRoutSub$ = this._activeRoute.queryParamMap.subscribe(
      (data: any) => {
        if (data.params.id && data.params.status && data.params.serviceId) {
          this.spinnerShow = true;
          const service = this.mainServices?.find(
            (item) => item.id === +data.params.serviceId,
          );
          this.paymentSub$ = this._paymentService
            .validSubscriptionPaymentID({
              paymentId: data.params.id,
            })
            .subscribe({
              next: (resData: any) => {
                this.spinnerShow = false;
                const dialogRef = this._dialog.open(MessageDialogComponent, {
                  panelClass: 'payment-dialog-success',
                  width: 'auto',
                  maxWidth: '90vw',
                  // height: '39rem',
                  data: {
                    flag: 'payment',
                    type: 'paymentSubscription',
                    message: `تم دفع تكلفة الخدمة ${service?.title} بنجاح`,
                  },
                });
                dialogRef.afterClosed().subscribe((x) => {
                  this._router.navigate(
                    [
                      data.params.entity
                        ? this.mainServices?.find(
                            (item) => item.id === +data.params.serviceId,
                          )?.type === 'case'
                          ? '/cases/my-cases'
                          : '/inquiry/my-inquiries'
                        : this.mainServices?.find(
                            (item) => item.id === +data.params.serviceId,
                          )?.type === 'case'
                        ? '/casetype'
                        : '/inquiry/create',
                    ],
                    {queryParams: {serviceId: +data.params.serviceId}},
                  );
                });
              },
              error: (errData: any) => {
                this.spinnerShow = false;
                this._snackBar
                  .open('فشل في عملية الدفع', 'X', {
                    panelClass: 'error-snackbar',
                  })
                  .afterDismissed()
                  .subscribe((x) => {
                    this._router.navigateByUrl(`/services/${service?.id}`);
                  });
              },
            });
        }
      },
    );
  }

  ngOnDestroy(): void {
    if (this.servicesListSub$) this.servicesListSub$.unsubscribe();
    if (this.subRoutSub$) this.subRoutSub$.unsubscribe();
    if (this.paymentSub$) this.paymentSub$.unsubscribe();
  }
}
