<div class="service-plans">
  <div class="service-plans__wrapper_content" *ngIf="mainServices && mainServices.length > 0">
    <ol class="service-plans__wrapper_content_list" #el>
      <!-- Service with plans section -->
      <li class="service-plans__wrapper_content_list__item" *ngFor="let service of mainServices" [id]="'_'+service.id">
        <!-- Side decorator -->
        <div class="service-plans__wrapper_content_list__item__circle circle">
          <div class="inner-circle"></div>
        </div>

        <!-- Main Services -->
        <div class="service-plans__service-category">
          <!-- Plans for each service -->
          <h3 class="service-plans__service-category__header">{{ service.title }}</h3>
          <div *ngIf="service.plans" class="service-plans__service-category__plans-wrapper">
            <!-- Plans items -->
            <div *ngFor="let plan of service.plans"
              class="service-plans__service-category__plans-wrapper__plan-item-wrapper">
              <app-plan-card [plan]="plan" [serviceTitle]="service.title" [serviceType]="service.type"
                [autoDiscountAvailablity]="service.autoDiscountAvailablity"
                [autoDiscount]="service.nextTwoCasesDiscountPercent" [slug]="service.slug"
                class="plan-item"></app-plan-card>
            </div>
          </div>
          <!-- Exemption request -->
          <app-exemption-request [serviceId]="service.id" [serviceType]="service.type"
            class="service-plans__service-category__exemption-request"></app-exemption-request>
        </div>

      </li>
    </ol>
  </div>
</div>