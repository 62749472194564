import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {
  Login,
  LoginService,
  OTPCredential,
  Register,
  RegisterService,
  ResetPassowrdData,
  User,
  UserService,
} from '@kortobaa-front/authentication';
import {environment} from 'src/environments/environment';
import {
  UpdatePasswordData,
  UpdateUserData,
} from '@kortobaa-front/authentication';

@Injectable()
export class AuthService {
  currentUser!: User;
  constructor(
    public user: UserService,
    private _login: LoginService,
    public registerService: RegisterService,
    private _http: HttpClient,
  ) {}

  //login user
  async login(data: Login) {
    return await this._login.login(data);
  }

  //verify login otp
  async verifyLoginOTP(data: OTPCredential) {
    return await this._login.validateOTP(data);
  }

  //register user
  register(data: Register): Observable<any> {
    // await this.registerService.register(data);
    return this._http.post(`${environment.api_url}users/signup`, data);
  }

  //verify register otp
  async verifyRegisterOTP(data: string) {
    await this.registerService.verifyAccount(data);
  }

  //forget password
  async forgetPass(data: ResetPassowrdData) {
    return await this.registerService.resetPassword(data);
  }

  //verify forget password
  async verifyForgetPassOTP(data: string) {
    await this.registerService.verifyResetPassword(data);
  }

  //resend otp for verify registeration
  async resendRegisterOTP(data: ResetPassowrdData) {
    await this.registerService.resetPassword(data);
  }

  //resend otp for verify and send new password
  async resendForgetPassOTP(data: ResetPassowrdData) {
    await this.registerService.resetPassword(data);
  }

  //resend otp for verify login
  async resendLoginOTP() {
    //TODO from backend and auth package
  }

  //complete user proile
  async compelteProfile(formData: FormData) {
    return this._http
      .patch(
        `${environment.api_url}user/additional-data`,
        formData,
        this.userToken(),
      )
      .toPromise();
  }

  // verify token to reset password
  verifyResetPassword(token: string, uuid: string) {
    return this._http.post(
      `${environment.api_url}users/verify/reset-change-password`,
      {token, uuid},
    );
  }

  // reset password
  resetPassword(body: any) {
    return this._http.post(
      `${environment.api_url}/users/reset-change-password`,
      body,
      // this.userToken(),
    );
  }

  async updateProfile(object: UpdateUserData) {
    await this.user.updateUser(object);
  }

  async updatePassword(object: UpdatePasswordData) {
    await this.user.updatePassword(object);
  }

  // get nationalities
  getNationalities() {
    return this._http.get(`${environment.api_url}user/nationalities`);
  }

  // send attachment
  async sendAttachment(formData: FormData) {
    return this._http
      .post(`${environment.api_url}attachments`, formData)
      .toPromise();
  }

  // check nid validator
  checkNid(body: any) {
    return this._http.post(`${environment.api_url}users/check-nid`, body);
  }

  // delete account
  deleteAccount(): Observable<any> {
    return this._http.delete(
      `${environment.api_url}users/me`,
      this.userToken(),
    );
  }

  //create header with user token
  userToken() {
    return {
      headers: {Authorization: `Bearer ${this.user.getToken()}`},
    };
  }
}
