import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-accept-estimation-dialog',
  templateUrl: './accept-estimation-dialog.component.html',
  styleUrls: ['./accept-estimation-dialog.component.scss'],
})
export class AcceptEstimationDialogComponent implements OnInit {
  confirm: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.loadScript();
  }

  //load script and take a callback function to trigger after script is loaded
  loadScript() {
    let node_1 = document.createElement('script');
    let node_2 = document.createElement('script');
    let linkStyle = document.createElement('link');
    linkStyle.rel = 'stylesheet';
    linkStyle.href = 'https://cdn.moyasar.com/mpf/1.7.3/moyasar.css';
    node_1.src = `https://polyfill.io/v3/polyfill.min.js?features=fetch`;
    node_1.id = 'pScript';
    node_2.src = `https://cdn.moyasar.com/mpf/1.7.3/moyasar.js`;
    node_2.id = 'p2Script';
    linkStyle.id = 'mysrLink';
    document.getElementsByTagName('head')[0].appendChild(linkStyle);
    document.getElementsByTagName('head')[0].appendChild(node_1);
    document.getElementsByTagName('head')[0].appendChild(node_2);
  }

  //remove script if exist
  removeScript() {
    let node_1: any = document.getElementById('pScript');
    let node_2: any = document.getElementById('p2Script');
    let linkStyle: any = document.getElementById('mysrLink');
    if (node_2) document.getElementsByTagName('head')[0].removeChild(node_2);
    if (node_1) document.getElementsByTagName('head')[0].removeChild(node_1);
    if (node_2) document.getElementsByTagName('head')[0].removeChild(linkStyle);
  }

  ngOnDestroy(): void {
    this.removeScript();
  }
}
