import {Component, Inject, OnInit} from '@angular/core';
import {CasesService} from '../../service/cases.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-attorney-view-dialog',
  templateUrl: './attorney-view-dialog.component.html',
  styleUrls: ['./attorney-view-dialog.component.scss'],
})
export class AttorneyViewDialogComponent implements OnInit {
  isCopiedPhone: boolean = false;
  isCopiedNid: boolean = false;
  userCase!: any;
  powers: any = [];
  powersArr = [
    { name: "المطالبة وإقامة الدعاوى", key: 1, completed: false },
    { name: "المرافعة و المدافعة", key: 2, completed: false },
    { name: "سماع الدعاوى والرد عليها", key: 3, completed: false },
    { name: "الإقرار", key: 4, completed: false },
    { name: "الإنكار", key: 5, completed: false },
    { name: "الصلح", key: 6, completed: false },
    { name: "التنازل", key: 7, completed: false },
    { name: "الإبراء", key: 8, completed: false },
    { name: "طلب اليمين ورده والامتناع عنه", key: 9, completed: false },
    { name: "إحضار الشهود والبينات والطعن فيها", key: 10, completed: false },
    { name: "طلب المنع من السفر ورفعه", key: 11, completed: false },
    { name: "مراجعة دوائر الحجز والتنفيذ", key: 12, completed: false },
    { name: "طلب الحجز والتنفيذ", key: 13, completed: false },
    { name: "الطعن بتقارير الخبراء والمحكمين وردهم واستبدالهم", key: 14, completed: false },
    { name: "المطالبة بتنفيذ الأحكام", key: 15, completed: false },
    { name: "قبول الأحكام ونفيها", key: 16, completed: false },
    { name: "الاعتراض على الأحكام وطلب الاستئناف", key: 17, completed: false },
    { name: "التماس إعادة النظر", key: 18, completed: false },
    { name: "التهميش على صكوك الاحكام", key: 19, completed: false },
    { name: "إنهاء ما يلزم حضور الجلسات في جميع الدعاوى لدى جميع المحاكم", key: 20, completed: false },
    { name: "قسمة التركة و فرز النصيب", key: 21, completed: false },
    { name: "استلام صكوك الأحكام", key: 22, completed: false },
    { name: "طلب الإدخال والتداخل", key: 23, completed: false },
    { name: "طلب إحالة الدعوى", key: 24, completed: false },
    { name: "لدى المحاكم", key: 25, completed: false },
    { name: "طلب نقض الحكم لدى المحكمة العليا", key: 26, completed: false },
    { name: "لدى المحكمة العليا", key: 27, completed: false },
    { name: "الإمهال", key: 28, completed: false },
    { name: "لدى المحاكم والاستئناف", key: 29, completed: false },
    { name: "لدى الاستلام والتسليم", key: 30, completed: false },
  ];
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.userCase = this.data.userCase;
    // get power data
    this.userCase.powerOfAttorney.permissions.map((item: any) => {
      this.powersArr.map((power: any) => {
        if (power.key == item) {
          this.powers.push(power);
        }
      });
    });
  }
  ngOnInit(): void {}

  //function for copy code
  copyToClipboard(applicationNo: string | undefined, type: string): void {
    navigator.clipboard.writeText(applicationNo as string);
    type === 'phone'
      ? (this.isCopiedPhone = true)
      : (this.isCopiedPhone = false);
    type === 'nid' ? (this.isCopiedNid = true) : (this.isCopiedNid = false);
  }
}
