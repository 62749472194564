import {Subscription} from 'rxjs';
import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {Router} from '@angular/router';
import {InquiryService} from 'src/app/inquiry/services/inquiry.service';
import {FooterService} from 'src/app/ui/service/footer.service';
import {NavbarService} from 'src/app/ui/service/navbar.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit {
  isLoaded: boolean = false;
  checkInquirySub$!: Subscription;
  // @ViewChild('inquiryBtn') inquiryBtn!: any;
  @ViewChild('servicePricesBtn') servicePricesBtn!: ElementRef;
  public screenWidth: any = window.innerWidth;

  constructor(
    private _navbarService: NavbarService,
    private _router: Router,
    private _footer: FooterService,
    private _inquiryService: InquiryService,
    private _snackBar: MatSnackBar,
    private _elementRef: ElementRef,
    private render: Renderer2,
  ) {
    this._navbarService.setNavbarVisiabilty(true);
    this._footer.setFooterVisibility(true);
  }

  ngOnInit(): void {}

  toggleIsLoaded() {
    this.isLoaded = true;
  }
  redirectToServicesPage(){
    this._router.navigateByUrl('/services');
  }

  // redirectToCreatePage() {
  //   this.checkInquirySub$ = this._inquiryService
  //     .checkInquiriesActive()
  //     .subscribe((res) => {
  //       if (res === null) {
  //         this._router.navigateByUrl('/inquiry/create');
  //       } else {
  //         this._snackBar.open(
  //           'لا يمكنك إنشاء استشارة جديدة. لديك استشارة لم تنتهي بالفعل',
  //           'X',
  //           {
  //             panelClass: 'error-snackbar',
  //           },
  //         );
  //       }
  //     });
  // }

  // ngAfterViewInit() {
  //   if (this.screenWidth <= 768) {
  //     this.render.listen('window', 'scroll', () => {
  //       if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
  //         this.inquiryBtn._elementRef.nativeElement.style.position = 'absolute';
  //         this._elementRef.nativeElement.querySelector(
  //           '.caseList',
  //         ).style.display = 'block';
  //         this._elementRef.nativeElement.querySelector(
  //           '.caseList',
  //         ).style.marginBottom = '10rem';
  //         this.inquiryBtn._elementRef.nativeElement.style.bottom = '2%';
  //       } else {
  //         this.inquiryBtn._elementRef.nativeElement.style.position = 'fixed';

  //         this.inquiryBtn._elementRef.nativeElement.style.bottom = '8%';
  //       }
  //     });
  //   }
  // }
  ngAfterViewInit() {
    if (this.screenWidth <= 768) {
      this.render.listen('window', 'scroll', () => {
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
          this.servicePricesBtn.nativeElement.style.position = 'absolute';
          this._elementRef.nativeElement.querySelector(
            '.servicesList',
          ).style.display = 'block';
          this._elementRef.nativeElement.querySelector(
            '.servicesList',
          ).style.marginBottom = '10rem';
          this.servicePricesBtn.nativeElement.style.bottom = '2%';
        } else {
          this.servicePricesBtn.nativeElement.style.position = 'fixed';

          this.servicePricesBtn.nativeElement.style.bottom = '8%';
        }
      });
    }
  }

  // ngOnDestroy(): void {
  //   if (this.checkInquirySub$) this.checkInquirySub$.unsubscribe();
  // }
}
