import { AuthService } from "./../../../core/services/auth.service";
import { Subscription } from "rxjs";
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { GenerateFormDataService } from "src/app/shared/services/generate-form-data.service";
import { CommentService } from "./comment.service";

@Component({
  selector: "app-comment",
  templateUrl: "./comment.component.html",
  styleUrls: ["./comment.component.scss"],
})
export class CommentComponent implements OnInit {
  constructor(
    private _answerComment: CommentService,
    private _generateFormData: GenerateFormDataService,
    private _auth: AuthService,
    private _snackBar: MatSnackBar,
  ) {

  }


  rolesValues: any = ["supervisors"];
  dropCheck: boolean = false;
  show: boolean = false;
  commentFiles: File[] | null | undefined = [];
  answerValue: string = "";
  files!: File[];
  MAXIMUM_IMAGE_SIZE: number = 2097152;
  isFilesInvalid: boolean | undefined = false;
  @ViewChild("commentFilesInput") commentFilesInput!: ElementRef;
  @Input() status!: string | undefined;
  @Input() appId!: number;
  @Input() app!: string;
  @Input() role!: string;
  @Input() hasRequest!: boolean;
  commentLoad: boolean = false;
  timeLogSub$!: Subscription;
  commentSub$!: Subscription;
  ngOnInit(): void { }



  //trigger the file input by click attach icon
  triggerUploadFiles(): void {
    this.commentFilesInput.nativeElement.click();
  }

  //remove file from selected files before submitting
  removeFile(deletedFile: File): void {
    this.commentFiles = this.commentFiles?.filter((file) => file != deletedFile);
  }

  //upload files to ready to submited with inquiry
  uploadFiles(event: any): void {
    this.files = event.target.files;
    this.commentFiles?.push(...this.files);

    // if (this.commentFiles) {
    //   if (this.commentFiles.length > 0) {
    //     this.commentFiles.push(...this.files);
    //   } else {
    //     this.commentFiles = [...this.files];
    //   }
    //   this.commentFiles.length > 11
    //     ? (this.isFilesInvalid = true)
    //     : (this.isFilesInvalid = false);
    // }

    this.isFilesInvalid = [...this.files]?.every((file, index) => {
      return file.size >= this.MAXIMUM_IMAGE_SIZE;
    });

    this.commentFiles = this.commentFiles?.filter((file, index) => {
      if (index == 9) {
        this._snackBar.open("حد أقصى عشر ملفات", 'X', {
          panelClass: "error-snackbar",
          // verticalPosition: "bottom",
          // duration: 1500,
        });
      }
      return file.size <= this.MAXIMUM_IMAGE_SIZE && index < 10;
    });

    this.commentFilesInput.nativeElement.value = "";
  }

  async submitComment(): Promise<void> {
    let obj: any = {};
    this.handleComment(obj);
    this.sendComment(obj);
  }

  //handle comment body to send it
  handleComment(obj: any) {
    this.rolesValues = ["supervisors", "conciliators", 'lawyers', 'callcenter', 'users'];
    for (var i = 0; i < this.rolesValues.length; i++) {
      obj[`roles_access[${[i]}]`] = this.rolesValues[i];
    }
    obj["usercase_id"] = this.appId;

    obj["description"] = this.answerValue;
  }

  //send comment to api
  sendComment(obj: any) {
    this.commentLoad = true;

    const formData = this._generateFormData.generateFormData({
      ...obj,
      ...this.commentFiles,
    });

    // send form Data to api
    this._answerComment.emitEvent(formData);

    // comment load when data send to api
    this._answerComment.emitEventLoad(true);

    // if data it sent remove loading
    this.commentSub$ = this._answerComment.getEventLoad().subscribe((data) => {
      // to remove after send comment
      if (data === false) {
        this.commentLoad = data;
        this.answerValue = "";
        this.commentFiles = [];

      }
    });
    if (this.timeLogSub$) this.timeLogSub$.unsubscribe();
  }
  //valid pattern comment
  validComment(value: any) {
    this.answerValue = value.control.value
      .replace(/[^/\n/\sa-zA-Z0-9 ٌ؛ء-ي’، ً ّ َ ُ؟‘ ِ ٍ~ْ\u0020-\u007e\u00a0-\u00ff\u0152\u0153\u0178\u0660-\u0669]{1,1000}/gm, "")
      .trim();
    return value.control.value
      .replace(/[^/\n/\sa-zA-Z0-9 ٌ؛ء-ي’، ً ّ َ ُ؟‘ ِ ٍ~ْ\u0020-\u007e\u00a0-\u00ff\u0152\u0153\u0178\u0660-\u0669]{1,1000}/gm, "")
      .trim();
  }


  ngOnDestroy(): void {
    if (this.timeLogSub$) this.timeLogSub$.unsubscribe();
    if (this.commentSub$) this.commentSub$.unsubscribe();
  }
}
