<div class="confirm-dialog">
    <!-- dialog header  -->
    <div class="confirm-dialog__header">
        <h2 class="confirm-dialog__header__service-title" *ngIf="data.serviceTitle">
            {{data.serviceTitle}}
        </h2>
        <h2 class="confirm-dialog__header__plan-title" *ngIf="data.plan">
            تفاصيل {{data.plan.title}}
        </h2>
    </div>
    <!-- dialog message  -->
    <div class="confirm-dialog__message">
        <div class="feature feature__note" *ngFor="let item of data.notes">
            <!-- <span class="feature-icon"> -->
            <mat-icon class="material-symbols-outlined note"> lens </mat-icon>
            <!-- </span> -->
            <p class="feature__info">
                {{ item.title }}
            </p>
        </div>
    </div>

    <!-- dialog buttons  -->
    <div mat-dialog-actions class="confirm-dialog__buttons">
        <button mat-button (click)="confirmDialog(data.plan)" class="confirm-dialog__buttons__confirm">
            المتابعة للدفع
        </button>

        <mat-icon mat-dialog-close aria-hidden="false" aria-label="Example close icon"
            class="material-icons-outlined confirm-dialog__buttons__close">
            close
        </mat-icon>
    </div>
</div>