<div class="comment-details__form" [ngClass]="{
    disabled: (status && (status === 'closed' || status === 'cancelled' || status === 'finished'))
  }">


  <!-- comment textarea -->
  <div class="comment-details__form__field" (click)="
      (status && status != 'closed' && status !== 'cancelled' && status != 'finished') || hasRequest == false
        ? (this.dropCheck = true)
        : (this.dropCheck = false)
    ">
    <textarea [ngClass]="{
        disabled: commentLoad
      }" class="warning-border comment-details__form__field__textarea" [(ngModel)]="answerValue" matInput
      name="description" #description="ngModel" placeholder="أضف تعليق" [disabled]="
        (status && (status === 'closed' || status === 'cancelled' || status === 'finished')) || hasRequest == true
      " (keypress)="validComment(description)" (change)="validComment(description)" (input)="validComment(description)"
      (paste)="validComment(description)"
      pattern="[/\n/\sa-zA-Z0-9 ٌ؛ء-ي’، ً ّ َ ُ؟‘ ِ ٍ~ْ\u0020-\u007e\u00a0-\u00ff\u0152\u0153\u0178\u0660-\u0669]{1,1000}$"
      minLength="1" maxLength="1000">
    </textarea>

    <small class="field__alert-message" *ngIf="
        !description?.valid &&
        description.errors &&
        description.touched &&
        description.dirty &&
        description.errors['pattern']
      ">لا يمكن ادخال حروف مميزة</small>
    <!-- <small class="field__alert-message" *ngIf="answerValue.length == 1000"> يجب الا يزيد الحروف عن 1000 حرف</small> -->
  </div>

  <!-- comment file field  -->
  <div class="comment-details__form__field-file">
    <input [ngClass]="{ disabled: isFilesInvalid === true || commentLoad }" (change)="uploadFiles($event)"
      class="warning-border" style="display: none" name="image" required #commentFilesInput type="file" multiple
      accept="application/pdf,image/png, image/jpg, image/jpeg" />
  </div>

  <!-- comment message for files ma size  -->
  <div class="comment-details__form__message">
    <div>
      <span class="comment-details__form__message_charater"
        [ngClass]="{ 'field__alert-message': answerValue.length == 1000 }" [ngStyle]="{
          display:
            (dropCheck && answerValue.length > 0) ||
            (this.commentFiles && this.commentFiles.length > 0) ||
            answerValue.length > 0
              ? 'block'
              : 'none'
        }">
        {{ answerValue.length }}/1000 حرف
      </span>

      <div class="comment-details__form__attach" (click)="triggerUploadFiles()" [ngStyle]="{
          display: this.commentFiles && this.commentFiles.length > 0 ? 'none' : 'flex'
        }" [ngClass]="{ disabled: commentLoad }">
        <mat-icon aria-hidden="false" aria-label="Example attach icon"
          class="attach-btn material-icons-outlined">attach_file</mat-icon>
        <span> إرفق ملف </span>
      </div>
    </div>
    <span [ngClass]="{ 'field__alert-message': isFilesInvalid }"> لا يزيد حجم المرفق عن 2Mb </span>
  </div>

  <!-- files badges wrapper  -->
  <div class="comment-details__form__files-wrapper" [ngStyle]="{
      display: this.commentFiles && this.commentFiles.length > 0 ? 'flex' : 'none'
    }">
    <div class="file-badge" *ngFor="let file of commentFiles">
      <!-- {{
        file.name.length > 10 ? '...' + file.name.substring(0, 10) : file.name
      }} -->
      <span *ngIf="file.type == 'application/pdf'">PDF</span>
      <span *ngIf="file.type == 'image/png' || file.type == 'image/jpg' || file.type == 'image/jpeg'">Image</span>
      <img *ngIf="file.type == 'application/pdf'" src="../../../../assets/images/attachment-pdf.png" />
      <img *ngIf="file.type == 'image/png' || file.type == 'image/jpg' || file.type == 'image/jpeg'"
        src="../../../../assets/images/attachment-img.png" />

      <mat-icon (click)="removeFile(file)" aria-hidden="false" aria-label="Example close icon"
        class="close-btn">close</mat-icon>
    </div>
    <!-- <mat-icon
      *ngIf="this.commentFiles && this.commentFiles.length < 10"
      [ngStyle]="{
        display: this.commentFiles && this.commentFiles.length > 0 ? 'block' : 'none'
      }"
      (click)="triggerUploadFiles()"
      aria-hidden="false"
      aria-label="Example add_circle icon"
      class="add-btn material-icons-outlined"
      >add_circle</mat-icon
    > -->

    <img class="add-btn" *ngIf="this.commentFiles && this.commentFiles.length < 10" [ngStyle]="{
        display: this.commentFiles && this.commentFiles.length > 0 ? 'block' : 'none'
      }" (click)="triggerUploadFiles()" src="../../../../assets/images/attach.png" alt="attach" />
  </div>

  <!-- comment answer field submit  -->
  <div class="comment-details__form__submit-btn-wrapper">
    <button [disabled]="answerValue.length == 0" [ngClass]="{
        disabled: answerValue.length == 0
      }" [ngStyle]="{
        display: !commentLoad ? 'block' : 'none'
      }" class="comment-details__form__submit-btn" type="button" mat-button (click)="submitComment()">
      إرسال الرد
    </button>
    <button [ngStyle]="{
        display: commentLoad ? 'block' : 'none'
      }" class="comment-details__form__submit-btn disabled" type="button" mat-button>
      <app-comment-spinner></app-comment-spinner>
    </button>
  </div>
</div>