<div class="container">
  <figure>
    <img src="../../../../assets/images/ozwah_layer.png" alt="جمعية عزوة" />
  </figure>
    <div class="content">

      <span class="loader"></span>
    </div>
    <div class="message">من فضلك انتظر لحظات قليلة</div>
</div>
