<div class="casetype-list">
  <!-- casetype list title  -->
  <h2 class="casetype-list__header">اختر قضيتك <span *ngIf="mainService">({{mainService.title}})</span></h2>
  <!-- casetype spinner  -->
  <div *ngIf="!cases" class="casetype-list__spinner">
    <mat-spinner></mat-spinner>
  </div>
  <!-- casetype items wrapper  -->
  <div *ngIf="cases" class="casetype-list__items-wrapper">
    <div *ngFor="let caseType of cases" class="casetype-list__item-wrapper">
      <!-- casetype item  -->
      <div
        class="casetype-list__item-wrapper__casetype-item"
        (click)="navigteToCreateCase(caseType.id)"
      >
        <figure>
          <img [src]="caseType.image_url" alt="case type" />
          <figcaption>
            <span
              *ngIf="caseType.title.length <= 20"
              [ngClass]="{'long-text': caseType.title.length > 20}"
            >
              {{ caseType.title }}</span
            >
            <span
              [ngClass]="{'long-text': caseType.title.length > 20}"
              *ngIf="caseType.title.length > 20"
              class="second"
              >{{ caseType.title }}</span
            >
          </figcaption>
        </figure>
      </div>
    </div>
  </div>
</div>
