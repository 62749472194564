import {HttpClient} from '@angular/common/http';
import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import { Subscription } from 'rxjs';
import {AuthService} from 'src/app/core/services/auth.service';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-search-dialog',
  templateUrl: './search-dialog.component.html',
  styleUrls: ['./search-dialog.component.scss'],
})
export class SearchDialogComponent {
  applicationNo: string = '';
  subSearchApplication$!:Subscription;
  constructor(
    private _snackbar: MatSnackBar,
    private _dialogRef: MatDialogRef<SearchDialogComponent>,
    private _http: HttpClient,
    private _auth: AuthService,
    private _router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  //search application by application_no
  searchApplication(): void {
    this.subSearchApplication$ = this._http
      .get(
        `${environment.api_url}/user/search-applications/${this.applicationNo}`,
        this._auth.userToken(),
      )
      .subscribe({
        next: (data: any) => {
          if (data.case_id) {
            this._router.navigateByUrl(`/cases/details/${data.id}`);
            this._dialogRef.close();
          } else {
            this._router.navigateByUrl(`/inquiry/details/${data.id}`);
            this._dialogRef.close();
          }
        },
        error: () => {
          this._dialogRef.close();
          this._snackbar.open('لا يوجد طلب بهذا الرقم',  'X', {
            panelClass: 'error-snackbar',
            // verticalPosition: 'bottom',
            // duration: 2000,
          });
        },
      });
  }

  ngOnDestroy(): void {
    if(this.subSearchApplication$) this.subSearchApplication$.unsubscribe()

  }
}
