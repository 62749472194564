import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GenerateFormDataService {
  constructor() {}

  generateFormData(object: any): FormData {
    let formData = new FormData();
    Object.keys(object).forEach((key) => {
      formData.append(key, object[key]);
    });
    return formData;
  }
}
