<div class="services-prices" *ngIf="!spinnerShow">
    <div *ngIf="!pageService && isloading" class="services-prices__spinner">
        <mat-spinner></mat-spinner>
    </div>

    <div *ngIf="pageService">
        <!-- Main Services -->
        <div class="service-plans__service-category">
            <!-- Plans for each service -->
            <h3 class="service-plans__service-category__header">{{ pageService.title }}</h3>
            <div *ngIf="pageService.plans" class="service-plans__service-category__plans-wrapper">
                <!-- Plans items -->
                <div *ngFor="let plan of pageService.plans"
                    class="service-plans__service-category__plans-wrapper__plan-item-wrapper">
                    <app-plan-card [plan]="plan" [serviceTitle]="pageService.title" [serviceType]="pageService.type"
                        [hasEntityFlag]="true" [autoDiscountAvailablity]="pageService.autoDiscountAvailablity"
                        [autoDiscount]="pageService.nextTwoCasesDiscountPercent" [slug]="pageService.slug"
                        class="plan-item"></app-plan-card>
                </div>
            </div>
            <!-- Exemption request -->
            <app-exemption-request [serviceId]="pageService.id" [serviceType]="pageService.type" [hasEntityFlag]="true"
                class="service-plans__service-category__exemption-request"></app-exemption-request>
        </div>
    </div>

    <div *ngIf="!pageService && !isloading" class="services-prices__not-found">
        <h2>لا يوجد خطط أسعار لتلك الخدمة بعد</h2>
    </div>
</div>

<div *ngIf="spinnerShow" class="ozwah-spinner">
    <app-ozwah-spinner></app-ozwah-spinner>
</div>