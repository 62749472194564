import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {CaseType} from 'src/app/core/interfaces/case-type.interface';
import {AuthService} from 'src/app/core/services/auth.service';
import {WarningDialogComponent} from '../../dialogs/warning-dialog/warning-dialog.component';
import {CaseTypeService} from '../../services/case-type.service';
import { ServicesListService } from '../../services/services-list.service';
import { MainService } from 'src/app/core/interfaces/main-service';

@Component({
  selector: 'app-casetype-list',
  templateUrl: './casetype-list.component.html',
  styleUrls: ['./casetype-list.component.scss'],
})
export class CasetypeListComponent implements OnInit {
  mainService !: MainService;
  cases: CaseType[] | null = null;
  caseTypeSub!: Subscription;
  checkHasCaseSub!: Subscription;
  subRoutSub$!: Subscription;
  mainServiceSub$ !: Subscription;
  constructor(
    private _router: Router,
    private _auth: AuthService,
    private _activeRoute: ActivatedRoute,
    private _caseTypeService: CaseTypeService,
    private _ServicesListService: ServicesListService,
    private _dialog: MatDialog,
  ) {
    //get all cases
    this.caseTypeSub = this._caseTypeService.getCaseType().subscribe((data) => {
      this.cases = data;
    });

    this.subRoutSub$ = this._activeRoute.queryParamMap.subscribe((urlData: any) =>{
      this.mainServiceSub$ = this._ServicesListService.getServiceById(+urlData.params.serviceId).subscribe(
        (serviceData) => {
          this.mainService = serviceData[0];
        }
      )
    })

  }

  ngOnInit(): void {}

  navigteToCreateCase(id: number){
    this.subRoutSub$ = this._activeRoute.queryParamMap.subscribe(
      (urlData: any) => {
        this._router.navigate(['/casetype/', id], {
          queryParams: {serviceId: urlData.params.serviceId},
        });
      })
  }

  //check whether user has open case of same type
  // checkUserHasCase(id: number, title: string) {
  //   this.subRoutSub$ = this._activeRoute.queryParamMap.subscribe(
  //     (urlData: any) => {
  //       const isUserLogged = this._auth.user.currentUser;
  //       if (!isUserLogged) {
  //         this._router.navigate(['/casetype/', id], {
  //           queryParams: {serviceId: urlData.params.serviceId},
  //         });
  //       } else {
  //         this.checkHasCaseSub = this._caseTypeService
  //           .checkUserHasCase(id)
  //           .subscribe({
  //             next: (data) => {
  //               if (!data) {
  //                 this._router.navigate(['/casetype/', id], {
  //                   queryParams: {serviceId: urlData.params.serviceId},
  //                 });
  //               } else {
  //                 const dialogRef = this._dialog.open(WarningDialogComponent, {
  //                   panelClass: 'confirm-dialog',
  //                   width: '58rem',
  //                   height: '39rem',
  //                   data: {
  //                     title: title,
  //                     usercase: data,
  //                   },
  //                 });
  //               }
  //             },
  //           });
  //       }
  //     },
  //   );
  // }

  ngOnDestroy(): void {
    if (this.caseTypeSub) this.caseTypeSub.unsubscribe();
    if (this.checkHasCaseSub) this.checkHasCaseSub.unsubscribe();
    if (this.subRoutSub$) this.subRoutSub$.unsubscribe();
    if (this.mainServiceSub$) this.mainServiceSub$.unsubscribe();
  }
}
