<div class="confirm-dialog">
    <!-- dialog message  -->
    <p class="confirm-dialog__message">
      لقد قمت بشراء هذه الخدمة بالفعل     
    </p>
    <!-- dialog icon  -->
    <mat-icon
      aria-hidden="false"
      aria-label="Example cancel icon"
      class="material-icons-outlined confirm-dialog__icon"
      >cancel</mat-icon
    >
    <!-- dialog title  -->
    <h2 class="confirm-dialog__title">هل تريد المتابعة وتقديم طلبك؟</h2>
    <!-- dialog buttons  -->
    <div mat-dialog-actions class="confirm-dialog__buttons">
      <button
        mat-button
        (click)="confirmDialog()"
        class="confirm-dialog__buttons__confirm"
      >
      <p *ngIf="data.type === 'subscription'">متابعة</p>
      <p *ngIf="data.type !== 'subscription'">مراجعة</p>

      </button>
      <button mat-button mat-dialog-close class="confirm-dialog__buttons__cancel">
        الغاء
      </button>
      <mat-icon
        mat-dialog-close
        aria-hidden="false"
        aria-label="Example close icon"
        class="material-icons-outlined confirm-dialog__buttons__close"
        >close</mat-icon
      >
    </div>
  </div>
  