import {Directive, Input, OnInit, OnDestroy} from '@angular/core';
import {Validator, NG_VALIDATORS, AbstractControl} from '@angular/forms';
import {Subscription} from 'rxjs';

@Directive({
  selector: '[ConfirmPassword]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: ConfirmPasswordDirective,
      multi: true,
    },
  ],
})
export class ConfirmPasswordDirective implements Validator, OnInit, OnDestroy {
  @Input() ConfirmPassword!: any;
  @Input() name!: string;
  private _sub!: Subscription;
  constructor() {}

  ngOnInit() {
    //validate to the password on every change in confirm password
    this._sub = this.ConfirmPassword.valueChanges.subscribe(() => {
      const confirmInput = this.ConfirmPassword.control.root.get(this.name);

      if (confirmInput) {
        //validation function
        this.validate(confirmInput);
      }
    });
  }

  validate(confirmInput: AbstractControl): any {
    //get the password input
    const passInput = confirmInput.root.get(this.ConfirmPassword.name);
    //check password match confirm password
    if (passInput && confirmInput.value !== passInput?.value) {
      //if not set compare error to true to be showen to user
      confirmInput.setErrors({compare: true});
      return {compare: true};
    }
    if (passInput && confirmInput.value === passInput.value) {
      //if it's a match then set errors to null
      // Reset the error
      confirmInput.setErrors({compare: false});
    }
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }
}
