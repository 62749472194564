<div class="search-dialog">
  <!-- search dialog title  -->
  <h2 class="search-dialog__title">استعلام</h2>
  <!-- search dialog content  -->
  <p class="search-dialog__message">ادخل رقم الطلب او الاستشارة</p>
  <!-- saerch dialog search input  -->
  <div class="field">
    <mat-form-field class="search-dialog__input" appearance="outline">
      <input
        class="warning-border"
        matInput
        required
        type="string"
        [(ngModel)]="applicationNo"
        onkeypress="return /[0-9]/i.test(event.key)"
      />
    </mat-form-field>
  </div>
  <!-- search dialog buttons -->
  <div class="search-dialog__buttons" mat-dialog-actions>
    <!-- search button  -->
    <button
      mat-button
      (click)="searchApplication()"
      class="search-dialog__buttons__search"
    >
      استعلام
    </button>
    <!-- close button  -->
    <mat-icon
      mat-dialog-close
      aria-hidden="false"
      aria-label="Example close icon"
      class="material-icons-outlined search-dialog__buttons__close"
      >close</mat-icon
    >
  </div>
</div>
