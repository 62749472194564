import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-comment-spinner",
  templateUrl: "./comment-spinner.component.html",
  styleUrls: ["./comment-spinner.component.scss"],
})
export class CommentSpinnerComponent implements OnInit {
  @Input() spinnerColor: string = "#f6f6f6"
  @Input() spinnerScale: string = "1"
  constructor() {}

  ngOnInit(): void {}
}
