import {Injectable} from '@angular/core';
import {AuthService} from 'src/app/core/services/auth.service';
import {environment} from 'src/environments/environment';
// import {getSHA256Hash} from 'boring-webcrypto-sha256';

@Injectable({
  providedIn: 'root',
})
export class SnapPixelService {
  user: any = {};
  SIGN_UP: any = {};
  PAGE_VIEW: any = {};
  PURCHASE: any = {};
  VIEW_CONTENT: any = {};
  item_ids: any;
  constructor(private _authService: AuthService) {
    if (this._authService?.user?.currentUser) {
      this.getSHA256Hash(this._authService.user.currentUser.email).then(
        (result: any) => {
          this.user['user_hashed_email'] = result;
        },
      ),
        this.getSHA256Hash(
          this._authService.user.currentUser.phone.replace('+', ''),
        ).then((result: any) => {
          this.user['user_hashed_phone_number'] = result;
        }),
        (this.user = {
          uuid_c1: this._authService.user.currentUser.nid,
          user_email: this._authService.user.currentUser.email,
          user_phone_number: this._authService.user.currentUser.phone.replace(
            '+',
            '',
          ),
        });
    }
  }

  //load script and take a callback function to trigger after script is loaded
  loadScript(email: string, action: string, obj?: any) {
    // sign up
    if (action == 'SIGN_UP' && obj) {
      this.getSHA256Hash(obj.user_email).then((result: any) => {
        this.user['user_hashed_email'] = result;
      }),
        this.getSHA256Hash(obj.user_phone_number).then((result: any) => {
          this.user['user_hashed_phone_number'] = result;
        }),
        (this.user = {
          uuid_c1: obj.uuid_c1,
          user_email: obj.user_email,
          user_phone_number: obj.user_phone_number,
        });

      this.SIGN_UP = {
        sign_up_method: 'Email',
        ...this.user,
      };
      // console.log('SIGN_UP', this.SIGN_UP);
    }
    // page view on service page
    if (action == 'PAGE_VIEW' && obj) {
      let body: any = {
        item_ids: [],
        item_category: [],
      };
      obj.forEach((element: any) => {
        body.item_category = [
          ...body.item_category,
          element.id + element.slug.replaceAll('_', ''),
        ];
        element.plans.forEach((plan: any) => {
          body.item_ids = [
            ...body.item_ids,
            plan.id + element.slug.replaceAll('_', ''),
          ];
        });
      });
      this.PAGE_VIEW = {
        item_ids: body.item_ids,
        item_category: body.item_category,
        ...this.user,
      };
      this.item_ids = this.PAGE_VIEW.item_ids;
      // console.log('PAGE_VIEW', this.PAGE_VIEW);
    }
    // VIEW_CONTENT when click on buy plan
    if (action == 'VIEW_CONTENT' && obj) {
      this.VIEW_CONTENT = {
        price: obj.price,
        currency: 'SAR',
        item_ids: this.item_ids.filter((item: any) => {
          return item.includes(obj.slug.replaceAll('_', ''));
        }),
        item_category: obj.serviceId + obj.slug.replaceAll('_', ''),
        ...this.user,
      };
      // console.log('VIEW_CONTENT', this.VIEW_CONTENT);
    }
    // PURCHASE when success PURCHASE
    if (action == 'PURCHASE' && obj) {
      let body = JSON.parse(sessionStorage.getItem('plan') || '{}');
      this.PURCHASE = {
        price: body.price,
        currency: 'SAR',
        transaction_id: obj.transaction_id,
        item_ids: body.id + obj.slug.replaceAll('_', ''),
        item_category: obj.item_category + obj.slug.replaceAll('_', ''),
        number_items: 1,
        ...this.user,
      };
      // console.log('PURCHASE', this.PURCHASE);
    }

    // Create script element
    let script = document.createElement('script');
    script.id = 'snap_pixel_script';

    // Construct Snap Pixel script
    script.innerHTML = `
    (function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function()
    {a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};
    a.queue=[];var s='script';r=t.createElement(s);r.async=!0;
    r.src=n;var u=t.getElementsByTagName(s)[0];
    u.parentNode.insertBefore(r,u);})(window,document,
              'https://sc-static.net/scevent.min.js');

    // Initialize Snap Pixel
    snaptr('init','${environment.snap_pixel_key}', {
      'user_email': '${email}' // Assuming email is the user's email
    });

    // Track an event
     snaptr('track',"${action}",${
      action == 'PAGE_VIEW'
        ? JSON.stringify(this.PAGE_VIEW)
        : action == 'PURCHASE'
        ? JSON.stringify(this.PURCHASE)
        : action == 'SIGN_UP'
        ? JSON.stringify(this.SIGN_UP)
        : JSON.stringify(this.VIEW_CONTENT)
    });
  `;

    // Append script to the header
    document.head.appendChild(script);
  }

  // create hash code
  async getSHA256Hash(input: string) {
    const textAsBuffer = new TextEncoder().encode(input);
    const hashBuffer = await window.crypto.subtle.digest(
      'SHA-256',
      textAsBuffer,
    );
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hash = hashArray
      .map((item) => item.toString(16).padStart(2, '0'))
      .join('');
    return hash;
  }

  //remove script if exist
  removeScript() {
    let head = document.getElementsByTagName('head')[0];
    let node_1: any = document.getElementById('snap_pixel_script');
    if (node_1) head.removeChild(node_1);
  }
}
