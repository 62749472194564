import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ElementRef,
} from '@angular/core';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  NgxAngularMaterialHijriAdapterService,
  DateLocaleKeys,
  MOMENT_HIJRI_DATE_FORMATS,
} from 'ngx-angular-material-hijri-adapter';
import {toGregorian} from 'hijri-converter';
@Component({
  selector: 'app-hijri-calender',
  templateUrl: './hijri-calender.component.html',
  styleUrls: ['./hijri-calender.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: NgxAngularMaterialHijriAdapterService,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MOMENT_HIJRI_DATE_FORMATS,
    },
    {provide: MAT_DATE_LOCALE, useValue: DateLocaleKeys.AR_SA},
  ],
})
export class HijriCalenderComponent implements OnInit {
  @Input() value: any;
  @Input() minDate: any;
  @Input() maxDate: any;
  @Input() name: any;
  @Input() placeholder: any;
  @Input() required: any = true;
  @Input() classModify!:any
  @Output() newItemEvent = new EventEmitter<{}>();
  requiredFlag: boolean = this.required;
  // months of hijri calender
  month: any = [
    {id: 1, name: 'محرم'},
    {id: 2, name: 'صفر'},
    {id: 3, name: 'ربيع الأول'},
    {id: 4, name: 'ربيع الثاني'},
    {id: 5, name: 'جمادى الأولى'},
    {id: 6, name: 'جمادى الآخرة'},
    {id: 7, name: 'رجب'},
    {id: 8, name: 'شعبان'},
    {id: 9, name: 'رمضان'},
    {id: 10, name: 'شوال'},
    {id: 11, name: 'ذو القعدة'},
    {id: 12, name: 'ذو الحجة'},
  ];

  constructor(private _elemRef: ElementRef) {}

  ngOnInit(): void {}

  // send date to profile page
  addDateHijri(value: string) {
    let elementValue = this._elemRef.nativeElement.querySelectorAll(
      `#${value}`,
    );
    let year = elementValue[0].value.split('،')[1];
    let month;
    let day = elementValue[0].value.split('،')[0].split(' ')[0];
    var ar = '٠١٢٣٤٥٦٧٨٩'.split('');
    var en = '0123456789'.split('');
    day = day.replace(/[٠١٢٣٤٥٦٧٨٩]/g, (x: any) => en[ar.indexOf(x)]);
    year = year.replace(/[٠١٢٣٤٥٦٧٨٩]/g, (x: any) => en[ar.indexOf(x)]);

    this.month.filter((item: any) => {
      if (
        item.name ==
          elementValue[0].value.split('،')[0].split(' ')[1] +
            ' ' +
            elementValue[0].value.split('،')[0].split(' ')[2] ||
        item.name == elementValue[0].value.split('،')[0].split(' ')[1]
      ) {
        month = item.id;
      }
    });

    // convert hijri date to gregorian date
    let gregDate = toGregorian(+year, Number(month), +day);

    // convert hijri date to gregorian date
    let HijriDate = `${+year}/${Number(month)}/${+day + 1}`;

    // emit date to birth date in profile page
    this.newItemEvent.emit({
      gregDate: new Date(
        `${gregDate.gy}/${gregDate.gm}/${gregDate.gd}`,
      ).toISOString(),
      hijriDate: new Date(HijriDate).toISOString(),
    });
  }
}
