<div class="create-case-dialog">
  <h2 class="create-case-dialog__title">اختر نوع القضية</h2>
  <form class="create-case-dialog__form" #chooseCaseForm="ngForm" (ngSubmit)="goToCase(chooseCaseForm)">
    <div class="create-case-dialog__form__field field-radio">
      <mat-radio-group ngModel required name="caseType" #caseType="ngModel">
        <mat-radio-button *ngFor="let case of cases" [value]="{id:case.id , title:case.title}">{{
          case.title
        }}</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="create-case-dialog__form__buttons" mat-dialog-actions>
      <button
        mat-button
        class="create-case-dialog__form__buttons__submit"
        [disabled]="chooseCaseForm.status == 'INVALID'"
        [ngStyle]="{opacity: chooseCaseForm.status == 'INVALID' ? 0.8 : 1}"
      >
        تقديم قضية
      </button>
      <mat-icon
        mat-dialog-close
        aria-hidden="false"
        aria-label="Example close icon"
        class="material-icons-outlined create-case-dialog__form__buttons__close"
        >close</mat-icon
      >
    </div>
  </form>
</div>
