import { HttpBackend, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UserService } from "@kortobaa-front/authentication";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { NotificationService } from "src/app/ui/components/notification/notification.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class FirebaseService {
  constructor(private _http: HttpClient, private _user: UserService , private _notification:NotificationService , private _httpBackend:HttpBackend) {
    this._http = new HttpClient(this._httpBackend)
  }

  async requestToken() {
    const messaging = getMessaging();
    try {
      let currentToken = await getToken(messaging, { vapidKey: environment.firebase.vapidKey });
      //logic after get token
      await this._http
        .post(`${environment.api_url}/register-token`, {
          platform: "Web",
          uuid: "123456789",
          token: currentToken,
        } , this.userToken())
        .subscribe({
          next: () => {
            //now this user is attached
          },
          error: () => {
            //something wen wrong
          },
        });
    } catch (err) {
      //something wen wrong
    }
  }

  listenOnNotification() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      this._notification.emitNewNotficationEvent(payload)
    });
  }

  logout() {
    return this._http.delete(`${environment.api_url}/users/logout`, this.userToken());
  }

  userToken() {
    return {
      headers: { Authorization: `Bearer ${this._user.getToken()}` },
      body: {
        platform: "Web",
        uuid: "123456789",
      },
    };
  }
}
