<div *ngIf="!sections" class="casetype-stepper-spinner">
  <mat-spinner></mat-spinner>
</div>
<div *ngIf="sections" class="casetype-stepper">
  <!-- TODO put limit to section title length will be backend or frontend -->
  <!-- start of steps -->

  <div class="steps">
    <div *ngFor="let section of sections ; let i = index" class="step active" [attr.data-index]="i"
      [style.order]="section.order">
      <div class="title" (click)="activateStep($event)">
        {{ section.title }}
      </div>
      <div class="icon" (click)="activateStep($event)">
        <mat-icon (click)="activateStep($event)" aria-hidden="false">check_circle</mat-icon>
      </div>
    </div>
  </div>
  <!-- end of steps -->

  <!-- start of steps-content  -->
  <div class="steps-content">
    <!-- Info box -->
    <div class="steps-content__info-box" *ngIf="!hasPaymentSubscription">
      <mat-icon aria-hidden="false" aria-label="info icon"
        class="steps-content__info-box__info-icon material-icons-outlined">info</mat-icon>
      <p>برجاء ملئ البيانات المطلوبة والمتابعة للدفع, أو اطلع على الخدمات والأسعار <span
          class="steps-content__info-box__plans-btn" (click)="navigateToServicePlans()"> من هنا</span></p>
    </div>

    <div *ngFor="let section of sections | sortObjects; let i = index" class="step-content">
      <!-- global form read values from rendered question only (section by section) -->
      <form #myform="ngForm" (ngSubmit)="submitAnswers(section.slug)" *ngIf="currentIndex == i">
        <div class="div">
          <div class="windows-wrapper" #questionElement id="questionElement">
            <div class="questions-wrapper">
              <!-- component render question depending on it's type  -->
              <app-casetype-question class="question-wrapper" *ngFor="let question of section.questions "
                [previousValue]="answers[section.slug]" [question]="question"
                [modifyObject]="modifyObject[section.slug]" [elementModify]="questionElement" [form]="myform"
                (questionCredibilityEvent)="checkQuestionCredibility($event)"></app-casetype-question>

              <!-- reconciliation box render depending is case need reconciliation or not  -->
              <div *ngIf="sections.length - 1 == i && hasReconciliation" class="question-reconciliation">
                <div class="question-reconciliation__box">
                  <label class="question-reconciliation__label">
                    هل تريد تخطى الصلح ؟
                    <span class="question-reconciliation__label__required">*</span>
                  </label>
                  <mat-radio-group class="question-reconciliation__radio" [value]="answers.reconciliation"
                    (change)="checkReconciliation($event.value)">
                    <mat-radio-button [value]="true || 'true'">نعم</mat-radio-button>
                    <mat-radio-button [value]="false">لا</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
              <!-- <div class="question-warning">
                <span class="question-reconciliation__label__required">*</span>
                <p>
                  ملحوظه : هذا لا يغير في صلاحية المشرف بإسناد القضية إلى مصلح
                  ان تطلب الامر
                </p>
              </div> -->
              <div *ngIf="sections.length - 1 == i" class="d-flex privacy" #lawsuitDataElement>
                <mat-checkbox required [checked]="answers.lawsuitData" (change)="
                    lawsuitData ? (lawsuitData = false) : (lawsuitData = true);
                    this.answers.lawsuitData = lawsuitData
                  ">أقر وأتعهد بصحة البيانات المدرجة</mat-checkbox>
                <mat-checkbox required [checked]="answers.lawsuitTerms" (change)="
                    lawsuitTerms
                      ? (lawsuitTerms = false)
                      : (lawsuitTerms = true);
                    this.answers.lawsuitTerms = lawsuitTerms
                  ">أوافق على
                  <a class="link" (click)="openTermsDialog()" target="_blank">الشروط و الأحكام</a></mat-checkbox>
              </div>
            </div>
          </div>
          <div class="windows-overlay"></div>
        </div>

        <!-- start of buttons warpper -->
        <div class="btns-wrapper">
          <!-- next button -->
          <button *ngIf="sections.length > currentIndex + 1" class="next-btn" type="button" mat-button
            (click)="saveAnswers(section.slug)">
            متابعة
          </button>
          <!-- submit button -->
          <button (click)="createCase(section.slug)" *ngIf="sections.length == currentIndex + 1" class="submit-btn"
          [ngStyle]="{display: !isLoading ? 'block' : 'none'}" type="button" [disabled]="isLoading" mat-button>
            {{
            sections.length == currentIndex + 1 ? hasPaymentSubscription? 'تقديم الطلب': 'المتابعة للدفع' : 'حفظ الطلب'
            }}
          </button>
          <button mat-flat-button class="submit-btn" type="button"
            [ngStyle]="{display: isLoading ? 'block' : 'none', padding: '1rem 5rem'}">
            <app-comment-spinner></app-comment-spinner>
          </button>
          <!-- reset button -->
          <!-- TODO add confirmation dialog to cancel everything (dialogs will be added in shared modules) -->
          <button *ngIf="sections.length == currentIndex + 1" (click)="cancelSubmit()" class="cancel-btn" type="button"
            mat-button>
            الغاء
          </button>
        </div>
        <!-- end of buttons warpper -->
      </form>
    </div>
  </div>
  <!-- end of steps-content  -->
</div>