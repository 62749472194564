import {NgIterable, Pipe, PipeTransform} from '@angular/core';
import {Question, Section} from 'src/app/core/interfaces/case-type.interface';

@Pipe({
  name: 'sortObjects',
})
export class SortObjectsPipe implements PipeTransform {
  //this pipe made for sorting sections and question in template depend on order property
  transform(value: Section[] | Question[], ...args: unknown[]): any{
    return value && value?.sort((a: Section | Question, b: Section | Question) =>
      a.order > b.order && a.order != b.order ? 1 : -1,
    );
  }
}
