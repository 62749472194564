import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {SnapPixelService} from 'src/app/shared/services/snap-pixel.service';

@Component({
  selector: 'app-plan-subscription-exist',
  templateUrl: './plan-subscription-exist.component.html',
  styleUrls: ['./plan-subscription-exist.component.scss'],
})
export class PlanSubscriptionExistComponent implements OnInit {
  constructor(
    private _router: Router,
    private dialogRef: MatDialogRef<PlanSubscriptionExistComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snapPixelService: SnapPixelService,
  ) {}

  ngOnInit(): void {}

  confirmDialog() {
    if (this.data.serviceType === 'case') {
      this.dialogRef.close('case');
    } else {
      this.dialogRef.close('inquiry');
    }
  }

  ngOnDestroy(): void {
    this._snapPixelService.removeScript();
  }
}
