<div class="evaluation">
  <div class="border"></div>
  <div class="evaluation-details__answers__questions">
    <!-- looping throw all question nested in that current section  -->
    <h3 class="title">
      {{ data.section.title }}
    </h3>
    <div
      *ngFor="let question of data.section.questions"
      class="evaluation-details__answers__question"
    >
      <h3 class="question">- {{ question.question }}</h3>
      <div class="answer_wrapper">
        <div
          class="answer"
          [ngClass]="{
            active: question.answer == eval.key
          }"
          *ngFor="let eval of data.evaluationAnswer"
          (click)="addEval(eval.key, data.section.role, question.id)"
        >
          {{ eval.title }}
        </div>
      </div>
    </div>
  </div>
</div>
