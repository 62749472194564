import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from '@kortobaa-front/authentication';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CasesService {
  constructor(private _http: HttpClient, private _user: UserService) { }

  //to add authorization body to request
  userToken() {
    return {
      headers: { Authorization: `Bearer ${this._user.getToken()}` },
    };
  }

  //drafts endpoint
  getDrafts(): Observable<any> {
    return this._http.get(
      `${environment.api_url}user/my-drafts`,
      this.userToken(),
    );
  }

  getDraftById(id: number): Observable<any> {
    return this._http.get(
      `${environment.api_url}user/drafts/${id}`,
      this.userToken(),
    );
  }

  updateDraftById(id: number, draft: any): Observable<any> {
    return this._http.patch(
      `${environment.api_url}user/drafts/${id}`,
      draft,
      this.userToken(),
    );
  }

  deleteDraftById(id: number): Observable<any> {
    return this._http.delete(
      `${environment.api_url}user/drafts/${id}`,
      this.userToken(),
    );
  }

  //usercase endpoint
  getModifiyCases(): Observable<any> {
    return this._http
      .get(`${environment.api_url}user/my-user-cases`, this.userToken())
      .pipe(
        map((data: any) => {
          return data.filter((caseItem: any) => caseItem.status == 'modify');
        }),
      );
  }

  getUsercases(limit: number = 0, skip: number = 0): Observable<any> {
    return this._http.get(
      `${environment.api_url}user/my-user-cases?filter={"where": {"status": {"nin":  ["modify"]} , "or": [{"paymentStatus": {"nin": [ "pending_estimation"]}},{"paymentStatus": null}]}, "limit":${limit} , "skip":${skip} , "order":["updated_at DESC"]}`,
      this.userToken(),
    );
  }

  //get paid usercases assigneed to supervisor
  getPaidUsercases(limit: number = 0, skip: number = 0): Observable<any> {
    return this._http.get(
      `${environment.api_url}user/my-user-cases?filter={"where": {"status": {"nin": ["modify"]},"paymentStatus": {"inq": ["paid_estimation", "subscription_payment"] }} , "limit":${limit} , "skip":${skip} , "order":["updated_at DESC"]}`,
      this.userToken(),
    );
  }

  //get pending usercases assigneed to supervisor
  getPendingUsercases(limit: number = 0, skip: number = 0): Observable<any> {
    return this._http.get(
      `${environment.api_url}user/my-user-cases?filter={"where": {"status": {"nin": ["modify"]},"paymentStatus": "pending_estimation"} , "limit":${limit} , "skip":${skip} , "order":["updated_at DESC"]}`,
      this.userToken(),
    );
  }

  //get declined usercases assigneed to supervisor
  getDeclinedUsercases(limit: number = 0, skip: number = 0): Observable<any> {
    return this._http.get(
      `${environment.api_url}user/my-user-cases?filter={"where": {"status": {"nin": ["modify"]},"paymentStatus": "declined_estimation"} , "limit":${limit} , "skip":${skip} , "order":["updated_at DESC"]}`,
      this.userToken(),
    );
  }

  getUsercasesCount(): Observable<any> {
    return this._http.get(
      `${environment.api_url}user/my-user-cases/count?where={"status": {"nin": ["modify"]} ,"or": [{"paymentStatus": {"nin": [ "pending_estimation"]}},{"paymentStatus": null}]}`,
      this.userToken(),
    );
  }

  //get all usercases has declined estimation assigneed to supervisor count
  getDeclinedEstimationCount(): Observable<any> {
    return this._http.get(
      `${environment.api_url}user/my-user-cases/count?where={"status": {"nin": ["modify"]} ,"paymentStatus":"declined_estimation"}`,
      this.userToken(),
    );
  }

  //get all usercases has paid estimation assigneed to supervisor count
  getPaidEstimationCount(): Observable<any> {
    return this._http.get(
      `${environment.api_url}user/my-user-cases/count?where={"status": {"nin": ["modify"]} ,"paymentStatus": {"inq": ["paid_estimation", "subscription_payment"] }}`,
      this.userToken(),
    );
  }

  //get all usercases has pending estimation assigneed to supervisor count
  getPendingEstimationCount(): Observable<any> {
    return this._http.get(
      `${environment.api_url}user/my-user-cases/count?where={"status": {"nin": ["modify"]} ,"paymentStatus":"pending_estimation"}`,
      this.userToken(),
    );
  }

  getUsercasesById(id: number): Observable<any> {
    return this._http.get(
      `${environment.api_url}user/user-cases/${id}`,
      this.userToken(),
    );
  }

  updateUsercasesById(id: number, answers: any): Observable<any> {
    return this._http.patch(
      `${environment.api_url}user/user-cases/${id}`,
      answers,
      this.userToken(),
    );
  }

  //cancel request endpoint
  createCancelRequest(cancelRequest: any): Observable<any> {
    return this._http.post(
      `${environment.api_url}user/cancellation-requests`,
      cancelRequest,
      this.userToken(),
    );
  }

  //create complain endpoint
  createComplain(complain: any): Observable<any> {
    return this._http.post(
      `${environment.api_url}user/complains`,
      complain,
      this.userToken(),
    );
  }

  //create comment
  createComment(commentBody: FormData): Observable<any> {
    return this._http.post(
      `${environment.api_url}user/comments`,
      commentBody,
      this.userToken(),
    );
  }

  // get latest updates and comments from api by userCase id
  getLatestUpdates(userCaseId: number): Observable<any> {
    return this._http.get(
      `${environment.api_url}user/comments/${userCaseId}`,
      this.userToken(),
    );
  }

  // add replay to comment and post it to api
  createReply(formData: FormData): Observable<any> {
    return this._http.post(
      `${environment.api_url}user/replies`,
      formData,
      this.userToken(),
    );
  }

  //get late time
  getLateTime() {
    return this._http.get(`${environment.api_url}get-late-usercases`);
  }

  //get complain by id
  getComplainById(complainId: number): Observable<any> {
    return this._http.get(
      `${environment.api_url}user/complains/${complainId}`,
      this.userToken(),
    );
  }

  // check lawsuit has complain active
  checkComplainActive(id: number): Observable<any> {
    return this._http.get(
      `${environment.api_url}user/user-has-complain/${id}`,
      this.userToken(),
    );
  }

  // reopen case temporary
  reOpenCaseTemp(id: any): Observable<any> {
    return this._http.post(
      `${environment.api_url}user/reopen-case-requests`,
      {
        userCaseId: id,
      },
      this.userToken(),
    );
  }

  // cancel estimation
  cancelEstimation(id: number, response: any): Observable<any> {
    return this._http.patch(
      `${environment.api_url}user/usercase-estimations/${id}`,
      response,
      this.userToken(),
    );
  }

  // add evaluation
  addEvaluation(response: any): Observable<any> {
    return this._http.post(
      `${environment.api_url}user/evaluations`,
      response,
      this.userToken(),
    );
  }
}
