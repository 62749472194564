import {
  Component,
  ElementRef,
  Inject,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-preview-images',
  templateUrl: './preview-images.component.html',
  styleUrls: ['./preview-images.component.scss'],
})
export class PreviewImagesComponent {
  pictures: any = [];
  currentPicture = 0;
  zoom = 1;
  @ViewChild('previewContainer') previewContainer!: ElementRef;
  constructor(
    private _elemRef: ElementRef,
    private _dialogRef: MatDialogRef<PreviewImagesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private renderer: Renderer2,
  ) {
    this.pictures = this.data.arr;
    // close gallery if enter outer image
    this.renderer.listen('window', 'click', (e: any) => {
      if (e.target?.id == 'previewContainer') {
        this._dialogRef.close();
      }
    });
  }

  // select right
  selectArrow() {
    this.zoom = 1;
    this._elemRef.nativeElement.querySelector(
      'img',
    ).style.transform = `scale(${1})`;
    let image = this.previewContainer.nativeElement.querySelector('#image');
    if (this.currentPicture < this.pictures.length - 1 && image) {
      this.currentPicture++;
      setTimeout(() => {
        image.classList.add('zoom');
      }, 0);
      // remove animation after add it
      setTimeout(() => {
        image.classList.remove('zoom');
      }, 600);
    }
  }

  // select left
  selectLeftArrow() {
    this.zoom = 1;
    this._elemRef.nativeElement.querySelector(
      'img',
    ).style.transform = `scale(${1})`;
    let image = this.previewContainer.nativeElement.querySelector('#image');
    if (this.currentPicture > 0 && image) {
      this.currentPicture--;
      setTimeout(() => {
        image.classList.add('zoom');
      }, 0);
      // remove animation after add it
      setTimeout(() => {
        image.classList.remove('zoom');
      }, 600);
    }
  }

  // zoom in and out image
  zoomIn() {
    if (this.zoom >= 2) {
      return;
    }
    this.zoom += 0.1;
    this._elemRef.nativeElement.querySelector(
      'img',
    ).style.transform = `scale(${this.zoom})`;
  }
  zoomOut() {
    if (this.zoom <= 1) {
      return;
    }
    this.zoom -= 0.1;
    this._elemRef.nativeElement.querySelector(
      'img',
    ).style.transform = `scale(${this.zoom})`;
  }
}
