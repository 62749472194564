import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {AuthService} from 'src/app/core/services/auth.service';
import {GenerateFormDataService} from 'src/app/shared/services/generate-form-data.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-complete-profile-dialog',
  templateUrl: './complete-profile-dialog.component.html',
  styleUrls: ['./complete-profile-dialog.component.scss'],
})
export class CompleteProfileDialogComponent implements OnInit {
  nidImage!: File | null;
  nidImageUrl: string | ArrayBuffer | null = '';
  MAXIMUM_IMAGE_SIZE: number = 2097152;
  isImageInvalid: boolean = false;
  SubmitProfileSub!: Subscription;
  @ViewChild('nid_image') nidImageInput!: ElementRef;
  constructor(
    private _generate: GenerateFormDataService,
    private _auth: AuthService,
    private _router: Router,
    private _snackBar: MatSnackBar,
    private _dialogRef: MatDialogRef<CompleteProfileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {}

  //upload image to submit the user profile
  onImgUploaded(event: any) {
    const uploadedImg = event.target.files[0];
    this.nidImage = uploadedImg;
    // Not allow to upload image which size is equal or more than 2MB
    this.isImageInvalid = uploadedImg.size >= this.MAXIMUM_IMAGE_SIZE;
    const reader = new FileReader();
    reader.readAsDataURL(uploadedImg);
    reader.onload = (_event) => {
      this.nidImageUrl = reader.result;
    };
  }

  //delete upload image before submitting
  removeImage() {
    this.nidImageUrl = '';
    this.nidImage = null;
    this.isImageInvalid = false;
    this.nidImageInput.nativeElement.value = '';
  }

  //trigger the file input by click attach icon
  triggerUploadNidImage() {
    this.nidImageInput.nativeElement.click();
  }

  //submit the photo and complete profile
  async submitProfile() {
    const formData = this._generate.generateFormData({
      image: this.nidImage,
    });
    await this._auth.compelteProfile(formData).then((data: any) => {
      const route = data?.route ? data?.route : '';
      this._router.navigateByUrl(route);
      this._dialogRef.close();
      this._auth.user
        .checkUserValid()
        .subsubcribe(() => {
          this._auth.user.currentUser.isFinishedAdditionalStep = true;
        })
        .catch((error: any) => {
          this._dialogRef.close();
          this._router.navigateByUrl('');
        });
    });
  }

  //logout from app
  logout() {
    this._auth.user.logout();
    this._router.navigateByUrl('');
    this._dialogRef.close();
    this._snackBar.open('تم تسجيل الخروج', 'X', {
      panelClass: 'error-snackbar',
      // duration: 2000,
      // verticalPosition: 'bottom',
    });
  }

  ngOnDestroy(): void {
    if (this.SubmitProfileSub) this.SubmitProfileSub.unsubscribe();
  }
}
