<div class="not-found-page">
  <div class="not-found-page__content">
    <img src="../../../../assets/images/empty-page.png" alt="empty page" />
    <h2 class="not-found-page__content__title">عذرا. يوجد خطأ بالصفحة</h2>
    <button class="not-found-page__content__btn" routerLink="/" type="button" mat-button>
      <mat-icon
        aria-hidden="false"
        aria-label="Home Add icon"
        class="material-icons-outlined"
        >home</mat-icon
      >
      <span> العودة للرئيسية </span>
    </button>
  </div>
</div>
