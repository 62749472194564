import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

@Pipe({
  name: "arabicTime",
})
export class ArabicTimePipe implements PipeTransform {
  transform(value: any, format?: any, ...args: unknown[]): unknown {
    moment.locale("ar");
    return moment(value).format(format ? format : "h:mm");
  }
}
