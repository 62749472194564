import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import { UserService } from '@kortobaa-front/authentication';
import {Observable} from 'rxjs';
import {MainService} from 'src/app/core/interfaces/main-service';
import { AuthService } from 'src/app/core/services/auth.service';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ServicesListService {
  
  constructor(
    private _http: HttpClient,
    private _user: UserService,
    private _auth: AuthService,
    ) {}

  userToken() {
    return {
      headers: {Authorization: `Bearer ${this._user.getToken()}`},
    };
  }

  //get all main services (doesn't require user token)
  getMainServices(): Observable<MainService[]> {
    const isUserLogged = this._auth.user.currentUser;
    if(isUserLogged){
      return this._http.get<MainService[]>(`${environment.api_url}user/services`,
      this.userToken()
      );
    }else{
      return this._http.get<MainService[]>(`${environment.api_url}user/services`);
    }
  }

  getServiceById(serviceId: number){
    return this._http.get<MainService[]>(`${environment.api_url}user/services?filter={"where" : {"id": ${serviceId}} }`);
  }
}
