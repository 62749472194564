<div class="confirm-dialog">
    <!-- confirm dialog title  -->
    <h2 class="confirm-dialog__title">الغاء</h2>
    <!-- confirm dialog icon  -->
    <mat-icon class="confirm-dialog__icon material-icons-outlined" aria-hidden="false" aria-label="Example cancel icon">cancel</mat-icon>
    <!-- confirm dialog message  -->
    <p class="confirm-dialog__message">هل أنت متأكد أنك تريد الالغاء</p>
    <!-- confirm dialog buttons  -->
    <div mat-dialog-actions class="confirm-dialog__buttons">
      <button mat-button mat-dialog-close class="confirm-dialog__buttons__cancel">لا</button>
      <button mat-button (click)="confirmDialog()" class="confirm-dialog__buttons__confirm">نعم</button>
        <mat-icon mat-dialog-close aria-hidden="false" aria-label="Example close icon" class="material-icons-outlined confirm-dialog__buttons__close">close</mat-icon>
    </div>
</div>
