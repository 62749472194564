import { Component, OnInit } from '@angular/core';
import { FooterService } from 'src/app/ui/service/footer.service';
import { NavbarService } from 'src/app/ui/service/navbar.service';

@Component({
  selector: 'app-caselist-page',
  templateUrl: './caselist-page.component.html',
  styleUrls: ['./caselist-page.component.scss']
})
export class CaselistPageComponent implements OnInit {
  isLoaded: boolean = false;

  constructor(
    private _navbarService: NavbarService,
    private _footer: FooterService,
  ) {
    this._navbarService.setNavbarVisiabilty(true);
    this._footer.setFooterVisibility(true);
   }

  ngOnInit(): void {
  }

  toggleIsLoaded() {
    this.isLoaded = true;
  }
}
