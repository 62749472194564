<div class="complete-profile">
  <!-- complete profile title  -->
  <h2 class="complete-profile__title">
    تلك المعلومات مطلوبة لاستكمال الملف الشخصي معنا
  </h2>

  <!-- complete profile form  -->
  <form
    class="complete-profile__form"
    #completeProfile="ngForm"
    (ngSubmit)="submitProfile()"
  >
    <div class="complete-profile__form__field field-file">
      <div class="field__content">
        <label class="field__label">
          ارفاق صوره الهوية او البطاقة العائلية
          <span class="field__label__required">*</span>
        </label>

        <!-- upload button  -->
        <div class="upload-btn" *ngIf="!this.nidImageUrl">
          <button type="button" mat-button (click)="triggerUploadNidImage()">
            <span>ارفاق</span>
            <mat-icon
              aria-hidden="false"
              aria-label="Example attach_file icon"
              class="material-icons-outlined close-btn"
              >attach_file</mat-icon
            >
          </button>
        </div>

        <!-- files badges wrapper  -->
        <div class="files-wrapper" *ngIf="this.nidImageUrl">
          <div class="file-badge">
            <span *ngIf="nidImage?.type == 'application/pdf'">PDF</span>
            <span
              *ngIf="
                nidImage?.type == 'image/png' ||
                nidImage?.type == 'image/jpg' ||
                nidImage?.type == 'image/jpeg'
              "
              >Image</span
            >
            <img
              *ngIf="nidImage?.type == 'application/pdf'"
              src="../../../../assets/images/attachment-pdf.png"
            />
            <img
              *ngIf="
                nidImage?.type == 'image/png' ||
                nidImage?.type == 'image/jpg' ||
                nidImage?.type == 'image/jpeg'
              "
              src="../../../../assets/images/attachment-img.png"
            />

            <mat-icon
              (click)="removeImage()"
              aria-hidden="false"
              aria-label="Example close icon"
              class="close-btn"
              >close</mat-icon
            >
          </div>
        </div>
      </div>
      <p>برجاء التأكد من ان الصورة المرفقة واضحة و صحيحة</p>

      <!-- image preview  -->
      <!-- <div
        class="complete-profile__form__field__preview-image"
        *ngIf="this.nidImageUrl"
      >
        <img [src]="this.nidImageUrl" alt="uploaded image" />
        <mat-icon
          aria-hidden="false"
          aria-label="Example attach_file icon"
          class="material-icons-outlined close-btn"
          (click)="removeImage()"
          >close</mat-icon
        >
      </div> -->

      <!-- image input  -->
      <input
        (change)="onImgUploaded($event)"
        class="warning-border"
        style="display: none"
        ngModel
        name="image"
        required
        #nid_image
        type="file"
        accept="image/*"
      />
      <!-- warning  -->
      <p
        *ngIf="this.nidImageUrl == ''"
        class="complete-profile__form__field__warning"
      >
        <mat-icon
          aria-hidden="false"
          aria-label="Example error icon"
          class="material-icons-outlined"
          >error</mat-icon
        >
        بدون استكمال تلك الخطوة لا يمكن تقديم قضية او استشارة
      </p>
    </div>

    <!-- <mat-divider></mat-divider>

    <div class="complete-profile__form__field field-file">
      <div class="field__content">
        <label class="field__label">
          ارفاق صوره شخصية
        </label>

        <div class="upload-btn" *ngIf="!this.nidImageUrl">
          <button type="button" mat-button (click)="triggerUploadNidImage()">
            <span>ارفاق</span>
            <mat-icon
              aria-hidden="false"
              aria-label="Example attach_file icon"
              class="material-icons-outlined close-btn"
              >attach_file</mat-icon
            >
          </button>
        </div>

        <div class="files-wrapper" *ngIf="this.nidImageUrl">
          <div class="file-badge">
            <span *ngIf="nidImage?.type == 'application/pdf'">PDF</span>
            <span
              *ngIf="
                nidImage?.type == 'image/png' ||
                nidImage?.type == 'image/jpg' ||
                nidImage?.type == 'image/jpeg'
              "
              >Image</span
            >
            <img
              *ngIf="nidImage?.type == 'application/pdf'"
              src="../../../../assets/images/attachment-pdf.png"
            />
            <img
              *ngIf="
                nidImage?.type == 'image/png' ||
                nidImage?.type == 'image/jpg' ||
                nidImage?.type == 'image/jpeg'
              "
              src="../../../../assets/images/attachment-img.png"
            />

            <mat-icon
              (click)="removeImage()"
              aria-hidden="false"
              aria-label="Example close icon"
              class="close-btn"
              >close</mat-icon
            >
          </div>
        </div>
      </div>

      <input
        (change)="onImgUploaded($event)"
        class="warning-border"
        style="display: none"
        ngModel
        name="image"
        #nid_image
        type="file"
        accept="image/*"
      />
    </div> -->

    <!-- dialog button  -->
    <div class="complete-profile__form__buttons">
      <!-- submit button  -->
      <button
        [ngStyle]="{
          opacity:
            completeProfile.status == 'INVALID' || nidImageUrl == '' ? 0.6 : 1
        }"
        [disabled]="completeProfile.status == 'INVALID' || nidImageUrl == ''"
        class="complete-profile__form__buttons__submit-btn"
        mat-button
      >
        تأكيد
      </button>

      <!-- logout button  -->
      <button
        (click)="logout()"
        type="button"
        class="complete-profile__form__buttons__logout-btn"
        mat-button
      >
        تسجيل خروج
      </button>
    </div>
  </form>
</div>
