import {FooterService} from './../../service/footer.service';
import {Subscription} from 'rxjs';
import {Component, OnInit} from '@angular/core';
import {NavbarService} from '../../service/navbar.service';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-ozwa-doc',
  templateUrl: './ozwa-doc.component.html',
  styleUrls: ['./ozwa-doc.component.scss'],
})
export class OzwaDocComponent implements OnInit {
  docSub$!: Subscription;
  contentTitle: string = '';
  public screenWidth: any = window.innerWidth;

  constructor(
    private _nav: NavbarService,
    private _route: ActivatedRoute,
    private _footer: FooterService,
    private _location: Location,
  ) {
    this._nav.setNavbarVisiabilty(true);
    this._footer.setFooterVisibility(true);

    // get data of filter from param
    this.docSub$ = this._route.params.subscribe((res: any) => {
      this.contentTitle = res.data;
    });

    if (this.screenWidth <= 768) {
      this._nav.setNavbarVisiabilty(false);
    }
  }

  ngOnInit(): void {}

  // go to back
  back() {
    this._location.back();
  }

  ngOnDestroy(): void {
    if (this.docSub$) this.docSub$.unsubscribe();
  }
}
