import {Component, Inject, OnInit} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {AcceptEstimationDialogComponent} from 'src/app/cases/dialogs/accept-estimation-dialog/accept-estimation-dialog.component';
import {PlanFeature, ServicePlan} from 'src/app/core/interfaces/service-plan';
import {SnapPixelService} from 'src/app/shared/services/snap-pixel.service';

@Component({
  selector: 'app-plan-details-dialog',
  templateUrl: './plan-details-dialog.component.html',
  styleUrls: ['./plan-details-dialog.component.scss'],
})
export class PlanDetailsDialogComponent implements OnInit {
  constructor(
    private _router: Router,
    private dialogRef: MatDialogRef<PlanDetailsDialogComponent>,
    private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snapPixelService: SnapPixelService,
  ) {}

  ngOnInit(): void {}

  confirmDialog(notes: any) {
    this.openAcceptPaymentDialog();
    this.dialogRef.close();
  }

  //open accept Payment dialog
  openAcceptPaymentDialog() {
    this._dialog.open(AcceptEstimationDialogComponent, {
      width: '64rem',
      maxWidth: '90vw',
      autoFocus: false,
      data: {
        type: 'subscription',
        body: {
          id: this.data.subscriptionId,
          amount: this.data.amount,
          serviceId: this.data.plan.serviceId.toString(),
          hasEntity: this.data.hasEntity,
        },
      },
    });
  }

  ngOnDestroy(): void {
    this._snapPixelService.removeScript();
    this.dialogRef.close();
  }
}
