<div
  [style.visibility]="paginatorSetting.length <= paginatorSetting.pageSize ? 'hidden' : 'visible'"
  class="pagination"
  [ngStyle]="{ width: width == '80%' ? width : width }"
>
  <!-- paginator option -hidden-  -->
  <mat-paginator
    style="display: none"
    [length]="paginatorSetting.length"
    [pageSize]="paginatorSetting.pageSize"
    (page)="onPageButton($event)"
  ></mat-paginator>
  <!-- previous button  -->
  <button
    class="pagination__btn"
    mat-icon-button
    [ngClass]="{ disabled: paginator.pageIndex === 0 }"
    (click)="paginator.previousPage()"
  >
    <mat-icon aria-hidden="false" aria-label="Example chevron_right icon">chevron_right</mat-icon>
  </button>
  <!-- pages numbers button  -->
  <mat-button-toggle-group class="pagination__toggle-btns" name="pageNav">
    <ng-container *ngFor="let page of [].constructor(paginatorSetting.pages); let i = index">
      <span class="pagination__toggle-btns__dots">

        {{i == [].constructor(paginatorSetting.pages).length-1 && this.paginator.pageIndex < [].constructor(paginatorSetting.pages).length-3 ? '..': null}}
      </span>
      <mat-button-toggle
        class="pagination__toggle-btns__btn"
        [checked]="this.paginator.pageIndex === i"
        (click)="onPageNum(i)"
        [ngClass]="{
          'pagination__toggle-btns__btn__active': this.paginator.pageIndex === i
        }"
        *ngIf="
          this.paginator.pageIndex-1 == i
        || i == this.paginator.pageIndex+1
        ||i == this.paginator.pageIndex
        ||i == [].constructor(paginatorSetting.pages).length-1
        ||i == 0
        "
      >
        {{ i + 1 }}
      </mat-button-toggle>
      <span class="pagination__toggle-btns__dots">

        {{i == 0 && this.paginator.pageIndex > i+2 ? '..': null}}
      </span>
    </ng-container>
  </mat-button-toggle-group>
  <!-- next button  -->
  <button
    class="pagination__btn"
    mat-icon-button
    [ngClass]="{ disabled: paginator.pageIndex + 1 === paginatorSetting.pages }"
    (click)="paginator.nextPage()"
  >
    <mat-icon aria-hidden="false" aria-label="Example chevron_left icon">chevron_left</mat-icon>
  </button>
</div>
