import {Component} from '@angular/core';
import {
  trigger,
  transition,
  group,
  query,
  style,
  animate,
} from '@angular/animations';
import {
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
  RouterEvent,
} from '@angular/router';
import {Subscription} from 'rxjs';
import {FirebaseService} from 'src/app/core/services/firebase.service';
import {UserService} from '@kortobaa-front/authentication';
import {NotificationService} from '../notification/notification.service';

@Component({
  selector: 'app-root',
  template: `
    <!-- start of overlay spinner for routes  -->
    <div *ngIf="loading" class="route-spinner">
      <mat-spinner></mat-spinner>
    </div>
    <!-- navbar for all screens  -->
    <app-navbar></app-navbar>
    <!-- notification display  -->
    <app-notification></app-notification>
    <!-- main display routes  -->
    <main [@routeAnimations]="prepareRoute(outlet)">
      <router-outlet #outlet="outlet"> </router-outlet>
    </main>
    <app-footer></app-footer>
  `,
  // styles: [
  //   'main{padding-top:0 ;position:relative;overflow:hidden;min-height: 90vh}',
  //   'main>*{display:block}',
  //   '.route-spinner{position:absolute;top:0;bottom:0;right:0;left:0;z-index:100;display:grid;place-items:center;background-color:white}',
  // ],
  styleUrls: ["./main.component.scss"],

  animations: [
    trigger('routeAnimations', [
      transition('3 => 2, 2 => 1', [
        style({height: '!'}),
        query(':enter', style({transform: 'translateX(100%)'})),
        query(
          ':enter, :leave',
          style({position: 'absolute', top: 0, left: 0, right: 0}),
        ),
        // animate the leave page away
        group([
          query(':leave', [
            animate(
              '0.3s cubic-bezier(.35,0,.25,1)',
              style({transform: 'translateX(-100%)'}),
            ),
          ]),
          // and now reveal the enter
          query(
            ':enter',
            animate(
              '0.3s cubic-bezier(.35,0,.25,1)',
              style({transform: 'translateX(0)'}),
            ),
          ),
        ]),
      ]),
      transition('1 => 2, 2 => 3', [
        style({height: '!'}),
        query(':enter', style({transform: 'translateX(-100%)'})),
        query(
          ':enter, :leave',
          style({position: 'absolute', top: 0, left: 0, right: 0}),
        ),
        // animate the leave page away
        group([
          query(':leave', [
            animate(
              '0.3s cubic-bezier(.35,0,.25,1)',
              style({transform: 'translateX(100%)'}),
            ),
          ]),
          // and now reveal the enter
          query(
            ':enter',
            animate(
              '0.3s cubic-bezier(.35,0,.25,1)',
              style({transform: 'translateX(0)'}),
            ),
          ),
        ]),
      ]),
    ]),
  ],
})
export class MainComponent {
  loading!: boolean;
  subRoute$!: Subscription;
  constructor(
    private _router: Router,
    private _firebase: FirebaseService,
    private _user: UserService,
    private _notify: NotificationService,
  ) {
    this.loading = false;
    this.subRoute$ = this._router.events.subscribe((event: any) => {
      if (event instanceof RouteConfigLoadStart) {
        this.loading = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loading = false;
      }
    });
  }
  ngOnInit(): void {
    this._user.checkUserValid().subscribe((data: any) => {
      if (data) {
        this._firebase.requestToken();
        this._firebase.listenOnNotification();
      }
    });
    this._notify.getUserIsAuthticated().subscribe((data: any) => {
      this._firebase.requestToken();
      this._firebase.listenOnNotification();
    });
  }
  prepareRoute(outlet: any) {
    return (
      outlet && outlet.activatedRouteData && outlet.activatedRouteData['depth']
    );
  }

  ngOnDestroy(): void {
    if (this.subRoute$) this.subRoute$.unsubscribe();
  }
}
