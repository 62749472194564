import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';
import {toGregorian} from 'hijri-converter';

@Pipe({
  name: 'hijriDate',
})
export class HijriDatePipe implements PipeTransform {
  transform(value: any, format?: any, ...args: unknown[]): unknown {
    moment.locale('ar');
    if(!value) return
    // months of hijri calender
    let months: any = [
      {id: 1, name: 'محرم'},
      {id: 2, name: 'صفر'},
      {id: 3, name: 'ربيع الأول'},
      {id: 4, name: 'ربيع الثاني'},
      {id: 5, name: 'جمادى الأولى'},
      {id: 6, name: 'جمادى الآخرة'},
      {id: 7, name: 'رجب'},
      {id: 8, name: 'شعبان'},
      {id: 9, name: 'رمضان'},
      {id: 10, name: 'شوال'},
      {id: 11, name: 'ذو القعدة'},
      {id: 12, name: 'ذو الحجة'},
    ];

    let year = value.split('-')[0];
    let month = value.split('-')[1];
    let day = value.split('-')[2].split('T')[0];
    var ar = '٠١٢٣٤٥٦٧٨٩'.split('');
    var en = '0123456789'.split('');
    day = day.replace(/[٠١٢٣٤٥٦٧٨٩]/g, (x: any) => en[ar.indexOf(x)]);
    year = year.replace(/[٠١٢٣٤٥٦٧٨٩]/g, (x: any) => en[ar.indexOf(x)]);

    months.filter((item: any) => {
      if (month == item.id) {
        month = item;
      }
    });
    let date;

    if (format == 'hijri') {
      date = ` ${+day} ${month.name}  ${+year} `;
    } else {
      let gregDate = toGregorian(+year, Number(month.id), +day);
      date = new Date(
        `${gregDate.gy}/${gregDate.gm}/${gregDate.gd}`,
      ).toISOString();
    }
    return date;
  }
}
