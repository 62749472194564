<div class="services-prices" *ngIf="!spinnerShow">
  <!-- Page description -->
  <h2 class="services-prices__header">الخدمات و الاسعار</h2>
  <p class="services-prices__description">
    تختص جمعية عزوة بتقديم الخدمات التالية للعون الحقوقى في قضايا الأحوال
    الشخصية مثل (طلاق، حضانة، نفقة.. إلخ)
  </p>
  <!-- Main services -->
  <app-services-list [mainServices]="mainServices"></app-services-list>
  <!-- Cases type -->
  <app-casestypes-list></app-casestypes-list>
  <!-- Services plans -->
  <app-service-plans></app-service-plans>
</div>

<div *ngIf="spinnerShow" class="ozwah-spinner">
  <app-ozwah-spinner></app-ozwah-spinner>
</div>