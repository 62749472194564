<div class="complain-details-dialog" *ngIf="complainData">
  <!-- complain details dialog  -->



  <div class="complain-details-dialog__content">
    <div class="complain-details-dialog__content__header">
      <h3>
        شكوي {{ complainData.usercase.caseType.title }} رقم
        {{ complainData.usercase.application_no }}
      </h3>
      <span>{{ complainData?.created_at | arabicDate : 'Do MMM' }}</span>
    </div>
    <p class="complain-details-dialog__content__description">
      {{ complainData.description }}
    </p>
    <div
      *ngIf="complainData?.file_url"
      class="complain-details-dialog__answer__attachments"
    >
      <a
        *ngFor="let attachment of complainData?.file_url"
        [href]="attachment.url"
        class="complain-details-dialog__answer__attachments__item"
      >
        <span *ngIf="attachment.fileType == 'application'">PDF</span>
        <span *ngIf="attachment.fileType == 'image'">Image</span>
        <img
          *ngIf="attachment.fileType == 'application'"
          src="../../../../assets/images/attachment-pdf.png"
        />
        <img
          *ngIf="attachment.fileType == 'image'"
          src="../../../../assets/images/attachment-img.png"
        />
      </a>
    </div>
    <div
      *ngIf="complainData?.answer"
      class="complain-details-dialog__answer-wrap"
    >
      <div class="complain-details-dialog__answer">
        <div class="complain-details-dialog__answer-inside">
          <h4>
            <!-- responser jon title  -->
            <span class="complain-details-dialog__answer__title">
              <!-- {{ complainData.userSupervisor?.username }}(مشرف) -->
              المشرف
            </span>
            <!-- answer date  -->
            <span class="complain-details-dialog__answer__date">
              <mat-icon aria-hidden="false" class="material-icons-outlined"
                >calendar_month</mat-icon
              >

              {{ complainData?.updated_at | arabicDate : 'Do MMM' }}
            </span>
          </h4>

          <!-- complain answer content  -->
          <!-- <p>{{ complain?.answer }}</p> -->
          <div
            class="complain-details-dialog__answer__desc"
            *ngIf="complainData?.answer.length > 0"
          >
            <p>
              {{ complainData?.answer }}
            </p>
          </div>
          <!-- complain answer attachment wrapper  -->
          <div
            *ngIf="complainData?.file_url"
            class="complain-details-dialog__answer__attachments"
          >
            <a
              *ngFor="let attachment of complainData?.file_url"
              [href]="attachment.url"
              class="complain-details-dialog__answer__attachments__item"
            >
              <span *ngIf="attachment.fileType == 'application'">PDF</span>
              <span *ngIf="attachment.fileType == 'image'">Image</span>
              <img
                *ngIf="attachment.fileType == 'application'"
                src="../../../../assets/images/attachment-pdf.png"
              />
              <img
                *ngIf="attachment.fileType == 'image'"
                src="../../../../assets/images/attachment-img.png"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
   <!--  complain details dialog buttons  -->
   <div class="complain-details-dialog__buttons" mat-dialog-actions>
    <!-- close button  -->
    <mat-icon
      mat-dialog-close
      aria-hidden="false"
      aria-label="Example close icon"
      class="material-icons-outlined complain-details-dialog__buttons__close"
      >close</mat-icon
    >
  </div>
</div>
