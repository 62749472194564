import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClient} from '@angular/common/http';

//modules
import {CaseTypeRoutingModule} from './case-type-routing.module';
import {SharedModule} from '../shared/shared.module';

//material modules
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatStepperModule} from '@angular/material/stepper';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';

//components
import {LandingPageComponent} from './pages/landing-page/landing-page.component';
import {CasetypeListComponent} from './components/casetype-list/casetype-list.component';
import {CasetypePageComponent} from './pages/casetype-page/casetype-page.component';
import {CasetypeStepperComponent} from './components/casetype-stepper/casetype-stepper.component';
import {CasetypeQuestionComponent} from './components/casetype-question/casetype-question.component';
import {WarningDialogComponent} from './dialogs/warning-dialog/warning-dialog.component';
import {CreateCaseComponent} from './dialogs/create-case/create-case.component';
import { TermsDialogComponent } from './dialogs/terms-dialog/terms-dialog.component';
import { ServicesListComponent } from './components/services-list/services-list.component';
import { CaselistPageComponent } from './pages/caselist-page/caselist-page.component';

@NgModule({
  declarations: [
    LandingPageComponent,
    CasetypeListComponent,
    CasetypePageComponent,
    CasetypeStepperComponent,
    CasetypeQuestionComponent,
    WarningDialogComponent,
    CreateCaseComponent,
    TermsDialogComponent,
    ServicesListComponent,
    CaselistPageComponent
  ],
  imports: [
    CommonModule,
    CaseTypeRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    //material modules
    MatCardModule,
    MatIconModule,
    MatStepperModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatRadioModule,
    MatCheckboxModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatDatepickerModule
  ],
  exports: [CreateCaseComponent],
  providers: [HttpClient],
})
export class CaseTypeModule {}
