import {NgForm} from '@angular/forms';
import {Component, OnInit} from '@angular/core';
import {FooterService} from '../../service/footer.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialogRef} from '@angular/material/dialog';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-contact-us-dialog',
  templateUrl: './contact-us-dialog.component.html',
  styleUrls: ['./contact-us-dialog.component.scss'],
})
export class ContactUsDialogComponent implements OnInit {
  description: string = '';
  constructor(
    private _footer: FooterService,
    private _snackBar: MatSnackBar,
    private _dialogRef: MatDialogRef<ContactUsDialogComponent>,
  ) {}
  userObj = JSON.parse(localStorage.getItem('UserProfile') || '{}');
  contactSub$!: Subscription;
  ngOnInit(): void {}

  //valid pattern comment
  validComment(value: any) {
    this.description = value.control.value
      .replace(
        /[^/\n/\sa-zA-Z0-9 ٌ؛ء-ي’، ً ّ َ ُ؟‘ ِ ٍ~ْ\u0020-\u007e\u00a0-\u00ff\u0152\u0153\u0178]{1,1000}/gm,
        '',
      )
      .trim();
    return value.control.value
      .replace(
        /[^/\n/\sa-zA-Z0-9 ٌ؛ء-ي’، ً ّ َ ُ؟‘ ِ ٍ~ْ\u0020-\u007e\u00a0-\u00ff\u0152\u0153\u0178]{1,1000}/gm,
        '',
      )
      .trim();
  }

  // submit contact
  // contactUsSubmit(form: NgForm) {
  //   let obj: any = {
  //     name: form.value.userName,
  //     email: form.value.email,
  //     phone: form.value.phone,
  //     message: form.value.message,
  //   };
  //   this.contactSub$ = this._footer.contactUs(obj).subscribe({
  //     next: (data) => {
  //       console.log(data);
  //       this._snackBar.open("تم إرسال رسالتك للنظام بنجاح ", undefined, {
  //         panelClass: "success-snackbar",
  //       });
  //     },
  //   });
  //   this._dialogRef.close();
  // }

  async contactUsSubmit(form: NgForm) {
    let obj: any = {
      name: form.value.userName,
      email: form.value.email,
      phone: form.value.phone,
      message: form.value.message,
    };
    await this._footer.contactUs(obj).subscribe({
      next: (data) => {
        this._snackBar.open('تم إرسال رسالتك للنظام بنجاح ', undefined, {
          panelClass: 'success-snackbar',
        });
      },
      error: () => {
        this._snackBar.open("برجاء إرسال رسالتك مرة أخري", 'X', {
          panelClass: 'error-snackbar',
          // verticalPosition: 'bottom',
          // duration: 2000,
        });
      },
    });
    this._dialogRef.close();
  }

  ngOnDestroy(): void {
    if (this.contactSub$) this.contactSub$.unsubscribe();
  }
}
