<div class="attorney-details-dialog">
  <!-- attorney details dialog  -->
  <div class="attorney-details-dialog__content">
    <div class="attorney-details-dialog__content__header">
      <h3>
        خطوات عمل توكيل لمحامي جمعية عزوة
      </h3>
    </div>
    <div class="attorney-details-dialog__content__list">
      <div class="attorney-details-dialog__content__list-item">
        <h5 class="item">
          الدخول لبوابة <a href="https://najiz.sa/applications/landing" alt="ناجز" target="_blank">ناجز</a> من حسابك لدى
          (النفاذ الوطني)
        </h5>
      </div>
      <div class="attorney-details-dialog__content__list-item">
        <h5 class="item">
          الدخول لقسم (الوكالات و الإقرارات) ثم انقر على زر المزيد
        </h5>
      </div>
      <div class="attorney-details-dialog__content__list-item">
        <h5 class="item">
          اختيار ( إنشاء وكالة جديدة) </h5>
      </div>

      <div class="attorney-details-dialog__content__list-item">
        <h5 class="item">
          اختيار ( إصدار وكالة فردية ) ثم تقديم طلب جديد </h5>
      </div>

      <div class="attorney-details-dialog__content__list-item">
        <h5 class="item">
          تعبئة بياناتك ثم انقر تأكيد ثم انقر التالي
        </h5>
      </div>
      <div class="attorney-details-dialog__content__list-item">
        <h5 class="item border-none">
          تعبئة بياناتك الوكيل( {{userCase.userAssignee.username}}) وفقا لما يلي :
        </h5>
        <div class="user_info_wrapper">
          <div class="user_info">
            <h4>رقم الهوية</h4>
            <div class="d-flex">
              <h5>{{userCase.userAssignee.nid}}</h5>
              <span>
                <mat-icon *ngIf="!isCopiedNid" aria-label="Example contect_copy icon"
                  (click)="copyToClipboard(userCase.userAssignee.nid, 'nid')"
                  class="material-icons-outlined">content_copy</mat-icon>
                <!-- icon after copying application number  -->
                <mat-icon matTooltipClass="tooltip-green" matTooltip="تم النسخ بنجاح" *ngIf="isCopiedNid"
                  aria-label="Example task icon" (click)="copyToClipboard(userCase.userAssignee.nid, 'nid')"
                  class="material-icons-outlined active">task_alt</mat-icon>
              </span>
            </div>
          </div>
          <div class="user_info">
            <h4>رقم الجوال</h4>
            <div class="d-flex">
              <h5 class="phone">{{userCase.userAssignee.phone}}</h5>
              <span>
                <mat-icon *ngIf="!isCopiedPhone" aria-label="Example contect_copy icon"
                  (click)="copyToClipboard(userCase.userAssignee.phone, 'phone')"
                  class="material-icons-outlined">content_copy</mat-icon>
                <!-- icon after copying application number  -->
                <mat-icon matTooltipClass="tooltip-green" matTooltip="تم النسخ بنجاح" *ngIf="isCopiedPhone"
                  aria-label="Example task icon" (click)="copyToClipboard(userCase.userAssignee.phone, 'phone')"
                  class="material-icons-outlined active">task_alt</mat-icon>
              </span>
            </div>
          </div>

          <div class="user_info">
            <h4>تاريخ الميلاد</h4>
            <h5>
              {{userCase.userAssignee.additionalData.birthdate
              ? (userCase.userAssignee.additionalData.birthdate
              | arabicDate : 'Do MMM yy')
              : (userCase.userAssignee.additionalData.birthdateHijri
              | hijriDate : 'hijri') }}
            </h5>
          </div>
        </div>
      </div>
      <div class="attorney-details-dialog__content__list-item">
        <h5 class="item">
          ثم انقر التحقق ثم إضافة ثم انقر التالى
        </h5>
      </div>
      <div class="attorney-details-dialog__content__list-item">
        <h5 class="item">
          من قائمة ( بنود الوكالة ) اختيار ( المطالبات و المحاكم )
        </h5>
      </div>
      <div class="attorney-details-dialog__content__list-item">
        <h5 class="item border-none">
          و قم بتحديد الصلاحيات التالية :
        </h5>
        <div class="attorney-badges">
          <div class="badge" *ngFor="let power of powers">
            {{power.name}}
          </div>

        </div>
      </div>
      <div class="attorney-details-dialog__content__list-info">
        <mat-icon aria-hidden="false" aria-label="Example attach icon"
          class="material-icons-outlined info" style="color: #ab1212 !important;">info</mat-icon>
        <h5 style="color: #ab1212 !important;">
          بعد الانتهاء من عمل التوكيل يرجي إرفاقة لمحامي جمعية عزوة كصورة
           أو  ملف PDF
        </h5>
      </div>
    </div>

  </div>
  <!--  attorney details dialog buttons  -->
  <div class="attorney-details-dialog__buttons" mat-dialog-actions>
    <!-- close button  -->
    <mat-icon mat-dialog-close aria-hidden="false" aria-label="Example close icon"
      class="material-icons-outlined attorney-details-dialog__buttons__close">close</mat-icon>
  </div>
</div>
