import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NavbarService {
  constructor() {}

  isNavbarVisible: boolean = false;

  setNavbarVisiabilty(flag: boolean): void {
    this.isNavbarVisible = flag;
  }
}
