<div class="accept-request-dialog" *ngIf="confirm == false">
  <!-- first screen for entering a reason  -->
  <!-- accept request dialog title  -->
  <h2 class="accept-request-dialog__title">تأكيد الدفع</h2>
  <!-- accept request dialog content  -->
  <div class="accept-request-dialog__message">
    سيتم دفع مبلغ
    <span> {{ ' ' + data.body.amount + ' ' }} ر.س </span>
    لصالح جمعية عزوة
  </div>
  <!-- accept request dialog buttons  -->
  <div class="accept-request-dialog__buttons" mat-dialog-actions>
    <!-- submit request button  -->
    <button
      mat-button
      class="accept-request-dialog__buttons__submit"
      (click)="confirm = confirm ? false : true"
    >
      تأكيد
    </button>
    <!-- close button  -->
    <mat-icon
      mat-dialog-close
      aria-hidden="false"
      aria-label="Example close icon"
      class="material-icons-outlined accept-request-dialog__buttons__close"
      >close</mat-icon
    >
  </div>
</div>

<app-payment-page [data]="data" *ngIf="confirm"></app-payment-page>
