<div class="services-list">
  <!--  spinner  -->
  <div *ngIf="!mainServices" class="services-list__spinner">
    <mat-spinner></mat-spinner>
  </div>

  <!-- Services List block -->
  <div *ngIf="mainServices" class="services-list__items-wrapper">
    <!-- Service item -->
    <div
      *ngFor="let service of mainServices"
      class="services-list__items-wrapper__service-item"
    >
      <figure>
        <img [src]="service.imageUrl" alt="service item" />
      </figure>
      <div class="overlay" (click)="scrollToPlan(service.id.toString())">
        <h4 class="overlay__header">
          <span>
            {{ service.title }}
          </span>
        </h4>
      </div>
    </div>
  </div>
</div>
