import { FooterService } from "./../../service/footer.service";
import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import {AuthService} from 'src/app/core/services/auth.service';
import {NavbarService} from '../../service/navbar.service';
import { ContactUsDialogComponent } from "../../dialog/contact-us-dialog/contact-us-dialog.component";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  constructor(
    public navbarService: NavbarService,
    public _auth: AuthService,
    private _router: Router,
    private _dialog: MatDialog,
    public _footer: FooterService
  ) {}

  ngOnInit(): void {}

  //open contact us dialog
  openContactUsDialog(): void {
    this._dialog.open(ContactUsDialogComponent, {
      minHeight: "60rem",
      width: "64rem",
      autoFocus: false,
      data: {},
    });
  }
}
