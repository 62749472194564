import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CasetypePageComponent} from './pages/casetype-page/casetype-page.component';
import {LandingPageComponent} from './pages/landing-page/landing-page.component';
import { CaselistPageComponent } from './pages/caselist-page/caselist-page.component';

const routes: Routes = [
  //sub-routes for casetype module.
  {component: LandingPageComponent, path: '', pathMatch: 'full'},
  {
    component: CaselistPageComponent,
    path: 'casetype',
    pathMatch: 'full',
  },
  {
    component: CasetypePageComponent,
    path: 'casetype/:id',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CaseTypeRoutingModule {}
