<div class="preview-image__container" #previewContainer id="previewContainer">
  <!-- preview image action download and close  -->
  <div class="preview-image__action" id="action" #action>
    <mat-icon mat-dialog-close aria-hidden="false" aria-label="Example close icon"
      class="material-icons-outlined close">close</mat-icon>

    <a [href]="pictures[currentPicture]" class="download">
      <mat-icon aria-hidden="false" aria-label="Example download icon"
        class="material-icons-outlined">file_download</mat-icon></a>

    <!-- <a class="btn zoom" (click)="zoomIn()" id="zoomIn">
      <mat-icon
        aria-hidden="false"
        aria-label="Example "
        class="material-icons-outlined"
        >zoom_in</mat-icon
      ></a
    >
    <a class="btn zoom-out" (click)="zoomOut()" id="zoomOut">
      <mat-icon
        aria-hidden="false"
        aria-label="Example "
        class="material-icons-outlined"
        >zoom_out</mat-icon
      ></a
    > -->
  </div>
  <!-- preview image  -->
  <div class="preview" id="previewImage " #previewImage>
    <img [ngClass]="{imgZoom: currentPicture == 0}" [src]="pictures[currentPicture]" id="image" #image />
  </div>
  <!-- preview image action right and left -->
  <div class="arrow-right" (click)="selectArrow()" id="right" #right>
    <mat-icon>arrow_forward_ios </mat-icon>
  </div>
  <div class="arrow-left" (click)="selectLeftArrow()" id="left" #left>
    <mat-icon> arrow_forward_ios </mat-icon>
  </div>
</div>