import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private _router: Router, private _auth: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // if user is logged in then redirect from auth module

    if (state.url == '/user/my-profile' && this._auth.user.currentUser) {      
      return true;
    } else if (!this._auth.user.currentUser && state.url != '/user/my-profile') {
      return true;
    } else {
      this._router.navigateByUrl('');
      return false;
    }
  }
}
