import {Directive, Input, OnInit, OnDestroy} from '@angular/core';
import {
  Validator,
  NG_VALIDATORS,
  AbstractControl,
} from '@angular/forms';
import {Subscription} from 'rxjs';

@Directive({
  selector: '[TemplateFormValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: TemplateFormValidatorDirective,
      multi: true,
    },
  ],
})
export class TemplateFormValidatorDirective
  implements Validator, OnInit, OnDestroy
{
  @Input() TemplateFormValidator!: any;
  @Input() name!: string;
  @Input() validator!: any;
  private _sub!: Subscription;
  constructor() {}

  ngOnInit() {
    //validate on the input every time value change
    this._sub = this.TemplateFormValidator.valueChanges.subscribe(() => {
      const input = this.TemplateFormValidator.control.root.get(this.name);
      if (input) {
        this.validate(input);
      }
    });
  }

  validate(input: AbstractControl): any {
    /* validator is function send in property on same element call validator
    which take function return false if validtion field or true if it's success */
    const isValid = this.validator(String(input.value));
    if (isValid) {
      input.setErrors(null);
    } else {
      input.setErrors({validator: true});
      return {validator: true};
    }
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }
}
