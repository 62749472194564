import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {RouterModule} from '@angular/router';

//material modules
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import {MatTooltipModule} from '@angular/material/tooltip';

//components
import {NavbarComponent} from './components/nav/navbar.component';
import {NotFoundPageComponent} from './pages/not-found-page/not-found-page.component';
import {FooterComponent} from './components/footer/footer.component';
import {ContactUsDialogComponent} from './dialog/contact-us-dialog/contact-us-dialog.component';
import {OzwaDocComponent} from './pages/ozwa-doc/ozwa-doc.component';
import {NotificationComponent} from './components/notification/notification.component';
import { CaseTypeModule } from '../case-type/case-type.module';
@NgModule({
  declarations: [
    NavbarComponent,
    NotFoundPageComponent,
    FooterComponent,
    ContactUsDialogComponent,
    OzwaDocComponent,
    NotificationComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    //material modules
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    MatDialogModule,
    MatDividerModule,
    MatTooltipModule,
    CaseTypeModule
  ],
  exports: [NavbarComponent, FooterComponent, NotificationComponent],
})
export class UiModule {}
