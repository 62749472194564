import {Component, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {ServicesListService} from '../../services/services-list.service';
import {MainService} from 'src/app/core/interfaces/main-service';

@Component({
  selector: 'app-services-list',
  templateUrl: './services-list.component.html',
  styleUrls: ['./services-list.component.scss'],
})
export class ServicesListComponent implements OnInit {
  checkInquirySub$!: Subscription;
  servicesListSub!: Subscription;
  mainServices: MainService[] | null = null;

  constructor(
    private _router: Router,
    private _ServicesListService: ServicesListService,
  ) {
    //get all main services
    this.servicesListSub = this._ServicesListService
      .getMainServices()
      .subscribe((data) => {
        this.mainServices = data;
      });
  }

  ngOnInit(): void {}

  navigateToDetails(serviceId: number, serviceType: string) {
    switch (serviceType) {
      case 'inquiry':
        this.redirectToCreatePage(serviceId);
        break;

      case 'case':
        this._router.navigate(['/casetype'],
        {queryParams: {serviceId: serviceId}});
        break;
    }
  }

  redirectToCreatePage(serviceId: number) {
    this._router.navigate(['/inquiry/create'],
          {queryParams: {serviceId: serviceId}});
  }

  ngOnDestroy(): void {
    if (this.servicesListSub) this.servicesListSub.unsubscribe();
  }
}
