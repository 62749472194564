<div class="casetype-details">
  <div class="casetype-details__header">
    <div>
      <!-- page title  -->
      <h2>
        <span>{{ caseTitle }}</span>
      </h2>
    </div>

    <!-- prev button  -->

    <div class="prev-button" [class.disabled]="getCurrentStep() == 0">
      <span (click)="prevStep()">
        <mat-icon aria-hidden="false" aria-label="Person Add icon"
          >chevron_right</mat-icon
        >
      </span>
      <span (click)="prevStep()">{{
        this.screenWidth > 768 ? 'السابق' : 1
      }}</span>

      <i *ngIf="getCurrentStep() != 0"></i>
      <span *ngIf="getCurrentStep() != 0" class="badge">{{
        getCurrentStep() + 1
      }}</span>

      <span>
        <mat-icon aria-hidden="false">chevron_left</mat-icon>
      </span>
    </div>
     
    <!-- draft button  -->
    <h3 *ngIf="!isUpdating && this.screenWidth > 768" (click)="saveDraft()">
      حفظ القضية كمسودة
    </h3>
    <mat-icon
      *ngIf="!isUpdating && this.screenWidth <= 768"
      aria-hidden="false"
      (click)="saveDraft()"
      >bookmark_border</mat-icon
    >
  </div>
  
  <div class="casetype-details__sections">
    <!-- component to render stepper form & take section as variable  -->
    <app-casetype-stepper
      [hasReconciliation]="hasReconciliation"
      [caseTypeId]="caseType_id"
      [draftServiceId]="+serviceId"
    ></app-casetype-stepper>
  </div>
</div>
