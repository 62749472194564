import {Component, Inject, OnInit} from '@angular/core';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss'],
})
export class BottomSheetComponent implements OnInit {
  constructor(
    private _bottomSheetRef: MatBottomSheetRef<BottomSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
  ) {
    this._bottomSheetRef.disableClose = true;
    this._bottomSheetRef.backdropClick().subscribe(() => {
      this._bottomSheetRef.dismiss({
        answer: this.data.answers,
        systemEval: this.data.systemEval,
        lawyerEval: this.data.lawyerEval,
      });
    });
  }

  ngOnInit(): void {}

  // add value to question
  addEval(value: number, role: string, key: any) {
    let obj: any = {};
    obj[`${key}`] = value;
    let find = [
      ...(role == 'system'
        ? this.data.systemEval
        : this.data.lawyerEval
      ).reduce(
        (s: any, o: any) => (Object.keys(o).forEach((k) => s.add(k)), s),
        new Set(),
      ),
    ];
    if (
      (role == 'system' ? this.data.systemEval : this.data.lawyerEval).length ==
        0 ||
      !find.includes(`${key}`)
    ) {
      (role == 'system' ? this.data.systemEval : this.data.lawyerEval).push(
        obj,
      );
    }
    if (find.includes(`${key}`)) {
      (role == 'system' ? this.data.systemEval : this.data.lawyerEval).map(
        (item: any): any => {
          if (item.hasOwnProperty(`${key}`)) {
            return (item[`${key}`] = value);
          }
        },
      );
    }
    this.data.answers.map((item: any): any => {
      item.questions.map((ques: any): any => {
        if (ques.id == key) {
          return (ques.answer = value);
        }
      });
    });

    (role == 'system' ? this.data.systemEval : this.data.lawyerEval).length ==
    this.data.section.questions.length
      ? this._bottomSheetRef.dismiss({
          answer: this.data.answers,
          systemEval: this.data.systemEval,
          lawyerEval: this.data.lawyerEval,
        })
      : '';
  }
}
