import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from '@kortobaa-front/authentication';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private _http: HttpClient, private _user: UserService) { }

  //to add authorization body to request
  userToken() {
    return {
      headers: { Authorization: `Bearer ${this._user.getToken()}` },
    };
  }

  // add payment
  addPayment(response: any): Observable<any> {
    return this._http.post(
      `${environment.api_url}/user/payments`,
      response,
      this.userToken(),
    );
  }

  // valid payment Id
  validPaymentID(response: any): Observable<any> {
    return this._http.post(
      `${environment.api_url}/user/payments/valid`,
      response,
      this.userToken(),
    );
  }

  validSubscriptionPaymentID(response: any): Observable<any> {
    return this._http.post(
      `${environment.api_url}/user/subscipition-payments/valid`,
      response,
      this.userToken(),
    );
  }

  validSubscriptionPaymentRemainID(response: any): Observable<any> {
    return this._http.post(
      `${environment.api_url}user/subscription-payments`,
      response,
      this.userToken(),
    );
  }

  // list of payments
  getListPayments(limit: number = 0, skip: number = 0): Observable<any> {
    return this._http.get(
      `${environment.api_url}/user/subscipition-payments?filter={"limit":${limit} , "offset":${skip},"order":["created_at DESC"]}`,
      this.userToken(),
    );
  }

  // list of payments counts
  getListPaymentCount(): Observable<any> {
    return this._http.get(
      `${environment.api_url}/user/subscipition-payments/count`,
      this.userToken(),
    );
  }
}
