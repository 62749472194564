import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Route, Router} from '@angular/router';
import {map, Subscription} from 'rxjs';
import {AuthService} from 'src/app/core/services/auth.service';
import {NavbarService} from 'src/app/ui/service/navbar.service';

import {CasetypeStepperComponent} from '../../components/casetype-stepper/casetype-stepper.component';
import {CheckLoginDialogComponent} from '../../../shared/dialogs/check-login-dialog/check-login-dialog.component';
import {CaseTypeService} from '../../services/case-type.service';
import {FooterService} from 'src/app/ui/service/footer.service';

@Component({
  selector: 'app-casetype-page',
  templateUrl: './casetype-page.component.html',
  styleUrls: ['./casetype-page.component.scss'],
})
export class CasetypePageComponent implements OnInit {
  //to access the properties of child component to get answers object for saving draft
  @ViewChild(CasetypeStepperComponent)
  public caseTypeComponent!: CasetypeStepperComponent;
  caseTitleSub: Subscription;
  activeRouteSub!: Subscription;
  getDraftSub!: Subscription;
  updateDraftSub!: Subscription;
  caseTitle!: string;
  caseType_id!: number;
  hasReconciliation!: boolean;
  prevStepCount: number = 1;
  isDraftSaved: boolean = false;
  draftId!: number;
  isUpdating: boolean = false;
  routeSub!: Subscription;
  serviceId !: string;
  public screenWidth: any = window.innerWidth;

  constructor(
    private _route: ActivatedRoute,
    private _caseTypeService: CaseTypeService,
    private _navbarService: NavbarService,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    private _auth: AuthService,
    private _footer: FooterService,
    private _router: Router,
  ) {
    this.caseType_id = Number(this._route.snapshot.paramMap.get('id'));
    //TODO add findById method in backend or add this logic to caseType service
    // to get caseType title & reconciliation
    this.caseTitleSub = this._caseTypeService
      .getCaseType()
      .subscribe((data) => {
        data.map((caseType) => {
          if (caseType.id == this.caseType_id) {
            this.caseTitle = caseType.title;
            this.hasReconciliation = caseType.hasReconciliation;
          }
        });
      });
    this._navbarService.setNavbarVisiabilty(true);
    this._footer.setFooterVisibility(true);
    this.routeSub = this._route.paramMap
      .pipe(map(() => window.history.state))
      .subscribe((data) => {
        if (data.usercaseId) {
          this.isUpdating = true;
        }
        if(data.serviceId){
          this.serviceId = data.serviceId;
        }
      });
  }

  ngOnInit(): void {
    this.activeRouteSub = this._route.paramMap
      .pipe(map(() => window.history.state))
      .subscribe((data) => {
        if (data.draftAnswer) {
          this.draftId = data.draftId;
        }
      });
    if (this._auth.user.currentUser) {
      this.getDraftSub = this._caseTypeService
        .getDraftByCaseId(this.caseType_id)
        .subscribe({
          next: (data: any) => {
            if (data.length != 0) {
              this.isDraftSaved = true;
              this.draftId = data[0].id;
              if(data[0].serviceId){
                this.serviceId = data[0].serviceId.toString();
              }
            }
          },
        });
    }
  }

  //to get to previous step
  prevStep() {
    this.caseTypeComponent.prevStep();
    this.prevStepCount = this.caseTypeComponent.currentIndex + 1;
  }


  //get current step to view in badge in template
  getCurrentStep() {
    return this.caseTypeComponent?.currentIndex || 0;
  }

  //save the draft with his answers
  saveDraft(): void {
    delete this.caseTypeComponent.answers.lawsuitTerms;
    delete this.caseTypeComponent.answers.lawsuitData;
    this.caseTypeComponent.myForm.ngSubmit.emit();
    const isUserLogged = this._auth.user.currentUser;
    if (isUserLogged) {
      let controllers = [
        ...Object.values(this.caseTypeComponent.myForm.controls),
      ];
      const validForm = controllers.every(
        (controller) => !controller.errors?.['pattern'],
      );
      const draft = {
        title: this.caseTitle,
        case_id: this.caseType_id,
        answers: this.caseTypeComponent.answers,
        serviceId: this.serviceId,
      };
      if (validForm)
        if (this.isDraftSaved) {
          this.updateDraftSub = this._caseTypeService
            .updateDraft(
              {answers: this.caseTypeComponent.answers},
              this.draftId,
            )
            .subscribe({
              next: (data) => {
                this._snackBar.open('تم حفط الطلب لاستكماله لاحقا', undefined, {
                  panelClass: 'success-snackbar',
                  duration: 1500,
                });
                this._router.navigateByUrl('/cases/my-cases');
              },
            });
        } else {
          this._caseTypeService.createDraft(draft).subscribe({
            next: (data) => {
              this._snackBar.open('تم حفط الطلب لاستكماله لاحقا', undefined, {
                panelClass: 'success-snackbar',
                duration: 1500,
              });
              this._router.navigateByUrl('/cases/my-cases');
            },
            error: () => {
              this._router.navigateByUrl('/cases/my-cases');
            },
          });
        }
    } else {
      const dialogRef = this._dialog.open(CheckLoginDialogComponent, {
        panelClass: 'white-dialog',
        width: '58rem',
        height: '39rem',
        data: {
          message: 'برجاء تسجيل الدخول لاستكمال طلبك',
        },
      });
    }
  }

  ngOnDestroy(): void {
    if (this.caseTitleSub) this.caseTitleSub.unsubscribe();
    if (this.activeRouteSub) this.activeRouteSub.unsubscribe();
    if (this.getDraftSub) this.getDraftSub.unsubscribe();
    if (this.updateDraftSub) this.updateDraftSub.unsubscribe();
    if (this.routeSub) this.routeSub.unsubscribe();
  }
}
