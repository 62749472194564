import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "min2hour",
})
export class Min2hourPipe implements PipeTransform {
  transform(value: number, format: string, ...args: unknown[]): any {
    if (format === "h") {
      if (Math.floor(value / 60) > 0) {
        return Math.floor(value / 60) + " ساعة ";
      }
    } else {
      return Math.floor(value / 60) == 0 ? (value % 60) + " دقيقة " : value % 60 > 0 ? (value % 60) + " :" : "";
    }
  }
}
