<div class="cases-types">
  <h2 class="cases-types__header">أنواع القضايا التي تقدمها المنصة</h2>
  <!-- Cases types List -->
  <div class="cases-types__list-wrapper">
    <!-- List Item -->
    <div
      *ngFor="let case of cases"
      class="cases-types__list-wrapper__list-item"
    >
      <span>{{ case.title }}</span>
    </div>
  </div>
</div>
