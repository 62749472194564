<footer class="footer__wrapper" *ngIf="_footer.isFooterVisible">
  <div class="footer__wrapper_content">
    <div class="footer__wrapper_content_item">
      <div class="footer__wrapper_content_item__logo" routerLink="/">
        <div class="img">
          <img src="../../../../assets/images/footer/logo0.png" alt="logo" />
        </div>
        <div class="sperator"></div>
      </div>
      <p>نحن معكِ عزوة و عون</p>
      <ul class="footer__wrapper_content_item_social">
        <li>
          <a href="https://instagram.com/ezoahksa?igshid=Zjc2ZTc4Nzk=" title="instagram" target="_blank"><img
              src="../../../../assets/images/footer/instagram.png" alt="instagram" /></a>
        </li>
        <li>
          <a href="https://twitter.com/EzoahKSA" title="twitter" target="_blank"><img
              src="../../../../assets/images/footer/twitter.png" alt="twitter" /></a>
        </li>
        <!-- <li>
          <a href="" title="facebook"><img src="../../../../assets/images/footer/facebook.png" alt="facebook" /></a>
        </li> -->
      </ul>
    </div>

    <div class="footer-flex">
      <div class="footer__wrapper_content_item">
        <ul>
          <li>
            <a class="link" routerLink="/info/service" routerLinkActive="active"> خدمات المنصة </a>
          </li>
          <li>
            <a class="link" routerLink="/info/about" routerLinkActive="active"> عن منصة عزوة </a>
          </li>
          <li>
            <a class="link" routerLink="/info/terms-conditions" routerLinkActive="active"> الشروط والاحكام </a>
          </li>
        </ul>
      </div>

      <div class="footer__wrapper_content_item">
        <ul>
          <li>
            <a class="link" routerLink="/info/privacy" routerLinkActive="active"> سياسة الخصوصية </a>
          </li>
          <!-- <li>
                  <a class="link" routerLink="/info/transparency-accountability" routerLinkActive="active">
                    الشفافية والمساءلة
                  </a>
                </li> -->
          <li>
            <a class="link" routerLink="/info/questions" routerLinkActive="active"> الأسئلة الشائعة </a>
          </li>
          <li>
            <a class="link" routerLink="/info/user-manual" routerLinkActive="active"> دليل الأستخدام </a>
          </li>
        </ul>
      </div>

      <div class="footer__wrapper_content_item contact-us">
        <!-- <button class="footer__wrapper_content_item_btn" type="button" mat-button (click)="openContactUsDialog()">
                <mat-icon aria-hidden="false" aria-label="call Add icon" class="material-icons-outlined">call</mat-icon>
                تواصل معنا
              </button> -->
        <div class="footer-app">
          <a href="https://play.google.com/store/apps/details?id=com.ezwah.beneficiary" target="_blank" class="app"
            aria-label="Google Play">
            حمل التطبيق
            <img class="img" src="../../../../assets/images/footer/google-app.png" alt="حمل التطبيق"
              title="حمل التطبيق" />
          </a>

          <a href="https://apps.apple.com/eg/app/%D8%AC%D9%85%D8%B9%D9%8A%D8%A9-%D8%B9%D8%B2%D9%88%D8%A9-%D8%A7%D9%84%D9%85%D8%B3%D8%AA%D9%81%D9%8A%D8%AF/id6502395812"
            target="_blank" class="app">
            حمل التطبيق
            <img class="img" src="../../../../assets/images/footer/apple-app.png" alt="حمل التطبيق"
              title="حمل التطبيق" />
          </a>
        </div>
        <a href="https://api.whatsapp.com/send/?phone=966504461988&text&type=phone_number&app_absent=0" target="_blank"
          class="footer__wrapper_content_item_btn">
          تواصل معنا
          <img class="footer__wrapper_content_item_btn_img" src="../../../../assets/images/footer/what'sapp.svg"
            alt="تواصل معنا" title="تواصل معنا" />
        </a>
      </div>
    </div>
  </div>
  <div class="d-flex">
    <div class="footer__wrapper_content_item_translate">
      <mat-icon aria-hidden="false" aria-label="call Add icon" class="material-icons-outlined">language</mat-icon>
      <p>العربية</p>
      <!-- <p class="eng">English</p> -->
    </div>
    <div class="footer__wrapper_content_item_copyRight">
      <p>جميع حقوق النشر محفوظة لمنصة عزوة 2022</p>
    </div>
  </div>
</footer>