import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UserService} from '@kortobaa-front/authentication';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PaymentSubscriptionService {
  private entityObject = new BehaviorSubject<any>({});
  private entityFiles = new BehaviorSubject<File[]>([]);

  constructor(private _http: HttpClient, private _user: UserService) {}

  private userToken() {
    return {
      headers: {Authorization: `Bearer ${this._user.getToken()}`},
    };
  }

  requestPaymentSubscription(formData: FormData): Observable<any> {
    return this._http.post(
      `${environment.api_url}user/subscriptions`,
      formData,
      this.userToken(),
    );
  }

  checkPaymentSubscription(serviceId: number): Observable<any> {
    return this._http.get(
      `${environment.api_url}user/subscriptions/${serviceId}`,
      this.userToken(),
    );
  }

  getSubscriptionByEntity(entityId: number, caseType:string){
    return this._http.get(
      `${environment.api_url}user/subscriptions/details?entityId=${entityId}&entityType=${caseType}`,
      this.userToken(),
    );
  }

  setEntityObject(newEntityObject:any){
    this.entityObject.next(newEntityObject);
  }

  getEntityObject(){
    return this.entityObject.asObservable();
  }

  setEntityFiles(newEntityFiles:File[]){
    this.entityFiles.next(newEntityFiles);
  }

  getEntityFiles(){
    return this.entityFiles.asObservable();
  }
  
}
