import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CaseTypeService } from 'src/app/case-type/services/case-type.service';
import { CaseType } from 'src/app/core/interfaces/case-type.interface';

@Component({
  selector: 'app-casestypes-list',
  templateUrl: './casestypes-list.component.html',
  styleUrls: ['./casestypes-list.component.scss']
})
export class CasestypesListComponent implements OnInit {
  caseTypeSub!: Subscription;
cases: CaseType[] | null = null;

  constructor(
    private _caseTypeService: CaseTypeService,
  ) { 
    //get all cases
    this.caseTypeSub = this._caseTypeService.getCaseType().subscribe((data) => {
      this.cases = data;
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.caseTypeSub) this.caseTypeSub.unsubscribe();
  }
}
