import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UserService } from "@kortobaa-front/authentication";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class FooterService {
  constructor(private _http: HttpClient, private _user: UserService) {}

  isFooterVisible: boolean = false;

  setFooterVisibility(flag: boolean): void {
    this.isFooterVisible = flag;
  }

  //respond to contact us
  contactUs(response: any): Observable<any> {
    return this._http.post(`${environment.api_url}contact-us`, response, this.userToken());
  }

  userToken() {
    return {
      headers: { Authorization: `Bearer ${this._user.getToken()}` },
    };
  }
}
