import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from '@kortobaa-front/authentication';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UploadAttachmentsService {
  constructor(
    private _http: HttpClient, 
    private _user: UserService
    ) {}

  private userToken() {
    return {
      headers: {Authorization: `Bearer ${this._user.getToken()}`},
    };
  }

  uploadAttachments(formData: FormData): Observable<any> {
    return this._http.post(
      `${environment.api_url}user/attachments`,
      formData,
      this.userToken(),
    );
  }
}
