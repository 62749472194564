import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import {catchError, Observable, tap, throwError} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslatePipe} from '../pipes/translate.pipe';
import {CompleteProfileDialogComponent} from 'src/app/shared/dialogs/complete-profile-dialog/complete-profile-dialog.component';
import {UserService} from '@kortobaa-front/authentication';
import {environment} from 'src/environments/environment';

@Injectable()
export class GlobalErrorHandler implements HttpInterceptor {
  constructor(
    private _snackBar: MatSnackBar,
    private _translatPipe: TranslatePipe,
    private _router: Router,
    private _dialog: MatDialog,
    private _user: UserService,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (environment.closeMaintenance) {
      this._router.navigateByUrl('/maintenance');
      return next.handle(request);
    } else {
      return next.handle(request).pipe(
        tap({
          error: (httpError) => {
            //destruct statuscode & error message
            const {statusCode, message} = httpError.error.error;
            switch (statusCode) {
              case 423:
                if (
                  request.url != `${environment.api_url}/register-token` &&
                  request.url !=
                    `${environment.api_url}/notifications-counter` &&
                  request.url != `${environment.api_url}/users/logout`
                ) {
                  if (this._dialog.openDialogs.length > 0) return;
                  const dialogRef = this._dialog.open(
                    CompleteProfileDialogComponent,
                    {
                      panelClass: 'white-dialog',
                      width: '58rem',
                      height: '67rem',
                      data: {route: ''},
                    },
                  );
                }

                break;
              // case of wrong entity
              case 422:
                this._snackBar.open('عذرا حدث خطأ ما...', 'X', {
                  panelClass: 'error-snackbar',
                  // verticalPosition: 'bottom',
                  // duration: 2000,
                });
                break;
              //case of unauthorized access
              case 401:
                const snackbar = this._snackBar.open(
                  'انت غير مصرح بذلك اعد تسجيل الدخول من فضلك.',
                  'X',
                  {
                    panelClass: 'error-snackbar',
                    // verticalPosition: 'bottom',
                    // duration: 2000,
                  },
                );
                throw httpError;
                break;
              //in case of unknown errors ... comes with custom message that get translated
              case 400:
                if (
                  request.url !== `${environment.api_url}user/coupons/validate` && request.url !== `${environment.api_url}user/subscriptions` 
                ) {
                  this._snackBar.open(
                    this._translatPipe.transform(message) as string,
                    'X',
                    {
                      panelClass: 'error-snackbar',
                      // verticalPosition: 'bottom',
                      // duration: 2000,
                    },
                  );
                }
                break;
              // in case server side error
              case 500:
                this._snackBar.open('عذرا وقع خطأ بالسيرفر...', 'X', {
                  panelClass: 'error-snackbar',
                  // verticalPosition: 'bottom',
                  // duration: 2000,
                });
                break;
              default:
                throw httpError;
            }
          },
        }),
      );
    }
  }
}
