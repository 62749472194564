import { Component, OnInit } from '@angular/core';
import { FooterService } from '../../service/footer.service';
import { NavbarService } from '../../service/navbar.service';

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss'],
})
export class NotFoundPageComponent implements OnInit {
  constructor(private _navbar: NavbarService, private _footer: FooterService
  ) {
    this._navbar.setNavbarVisiabilty(false);
    this._footer.setFooterVisibility(true);

  }

  ngOnInit(): void { }
}
