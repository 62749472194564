<section class="page-wrapper" [ngStyle]="{
    'min-height':
      contentTitle == 'user-manual'
        ? '100vh'
        : contentTitle == 'privacy'
        ? '215vh'
        : '90vh'
  }">
  <div class="page-wrapper_content">
    <div class="page-wrapper_content_header">
      <mat-icon (click)="back()" aria-hidden="false" aria-label="Example close icon"
        class="material-icons-outlined">close</mat-icon>
      <h1>
        {{
        contentTitle == 'service'
        ? 'خدمات المنصة'
        : contentTitle == 'about'
        ? 'عن منصة عزوة'
        : contentTitle == 'privacy'
        ? 'سياسة الخصوصية'
        : contentTitle == 'transparency-accountability'
        ? 'الشفافية والمساءلة'
        : contentTitle == 'questions'
        ? 'الأسئلة الشائعة'
        : contentTitle == 'terms-conditions'
        ? 'الشروط والاحكام'
        : 'دليل الأستخدام'
        }}
      </h1>
    </div>

    <ng-container *ngIf="contentTitle == 'service'">
      <h5>تقدم منصة عزوة الخدمات القانونية التالية :</h5>
      <ul>
        <li>
          <p>
            خدمات الترافع: خدمة يتم خلالها ترشيح محامي مرخص للترافع عن المستفيد
            أمام المحكمة المختصة في الدعوى القضائية لحين صدور الحكم.
          </p>
        </li>
        <li>
          <p>
            خدمات الاستشارات القانونية: خدمة يتم خلالها الإجابة على استفسارات
            المستفيد وتقديم المعلومات القانونية له مع إعطائه التوصيات والنصائح
            الهامة له.
          </p>
        </li>
        <li>
          <p>
            الخدمات القانونية الكتابية والإجرائية: خدمة يتم خلالها ربط المستفيد
            بمختص قانوني لكتابة المذكرات القانونية أو لإتمام الإجراءات التقنية
            المطلوبة في القضية الخاصة به، والمتعلقة بالتقاضي أو التنفيذ أو
            التوثيق وذلك عبر بوابة ناجز التابعة لوزارة العدل.
          </p>
        </li>
      </ul>
    </ng-container>

    <ng-container *ngIf="contentTitle == 'about'">
      <h5>منصة تتبع جمعية عزوة للحماية الأسرية</h5>
      <p>
        مسجلة لدى المركز الوطني لتنمية القطاع غير الربحي برقم (2232) وتاريخ
        01/07/1443هـ، وتختص بتقديم خدمات الترافع والاستشارات القانونية في قضايا
        الأحوال الشخصية، من خلال الاستعانة بمحامين ومحاميات مرخصين من وزارة
        العدل ومؤهلين لتقديم تلك الخدمات القانونية.
      </p>
    </ng-container>

    <ng-container *ngIf="contentTitle == 'user-manual'">
      <h5>الخدمات القانونية المقدمة:</h5>
      <ul>
        <li>
          <p>
            خدمات الترافع: خدمة يتم خلالها ترشيح محامي مرخص للترافع عن المستفيد
            أمام المحكمة المختصة في الدعوى القضائية لحين صدور الحكم.
          </p>
        </li>
        <li>
          <p>
            خدمات الاستشارات القانونية: خدمة يتم خلالها الإجابة على استفسارات
            المستفيد وتقديم المعلومات القانونية له مع إعطائه التوصيات والنصائح
            الهامة له.
          </p>
        </li>
        <li>
          <p>
            الخدمات القانونية الكتابية والإجرائية: خدمة يتم خلالها ربط المستفيد
            بمختص قانوني لكتابة المذكرات القانونية أو لإتمام الإجراءات التقنية
            المطلوبة في القضية الخاصة به، والمتعلقة بالتقاضي أو التنفيذ أو
            التوثيق وذلك عبر بوابة ناجز التابعة لوزارة العدل.
          </p>
        </li>
      </ul>

      <h5>إجراءات التقديم لجميع الخدمات القانونية في قضايا الأحوال الشخصية:</h5>
      <ul>
        <li>
          <p>تقديم الطلب عن طريق المنصة الإلكترونية للجمعية.</p>
        </li>
        <li>
          <p>تعبئة البيانات الأساسية لطالب الخدمة وإعداد الحساب الخاص به.</p>
        </li>
        <li>
          <p>
            إرفاق الهوية الوطنية أو الإقامة النظامية والتي يجب أن تكون سارية
            وغير منتهية.
          </p>
        </li>
        <li>
          <p>
            تعبئة البيانات الخاصة بطلب الخدمة وأرفاق المستندات المرتبطة بالطلب
            مثل (عقد النكاح، صك الطلاق إن وجد، الأحكام القضائية المرتبطة بالطلب،
            ونحوهم).
          </p>
        </li>
        <li>
          <p>
            دفع الرسوم الخاصة بالخدمة، وعند عدم مقدرة المستفيد على الدفع يمكن له
            طلب الإعفاء من الدفع مع إرفاق ما يثبت عجزه عن تحمل الرسوم، مثل:
            (بطاقة مستفيد من الضمان الاجتماعي، إشعار عدم التحاقه بالعمل من موقع
            المؤسسة العامة للتأمينات الاجتماعيات، شهادة تبين انخفاض الأجر من
            المؤسسة العامة للتأمينات الاجتماعيات، تقرير المالي من موقع سمه الذي
            يبين السجل الائتماني للمستفيد من ناحية القروض والبطاقات الائتمانية)
          </p>
        </li>
      </ul>

      <h5>الإجراءات الخاصة للتقديم على خدمة الترافع:</h5>
      <ul>
        <li>
          <p>التأكد من فتح حساب في ناجر التابع لوزارة العدل.</p>
        </li>
        <li>
          <p>عمل وكالة للمحامي المسؤول عن القضية.</p>
        </li>
        <li>
          <p>تزويد المحامي بجميع المستجدات في حينه.</p>
        </li>
      </ul>
    </ng-container>

    <ng-container *ngIf="contentTitle == 'privacy'">
      <h5>
        سياسة الخصوصية لمنصة جمعية عزوة للخدمات القانونية المتعلقة بنظام الأحوال
        الشخصية.
      </h5>
      <p>
        نحن في جمعية عزوة نحرص على حماية خصوصية زوار ومستخدمي منصتنا. في هذا
        الإطار، فإن سياسة الخصوصية الخاصة بنا توضح الطريقة التي نتعامل بها مع
        المعلومات الشخصية والتي يتم جمعها أثناء استخدام منصتنا، بما في ذلك
        المعلومات التي يتم جمعها من خلال استمارات الاتصال والاستفسارات والحسابات
        التي تم إنشاؤها وغيرها من الأنشطة المرتبطة بالمنصة.
      </p>

      <h5>
        المعلومات التي نجمعها:
        <br />
        <br />
        نجمع بعض المعلومات التي يتم تحديدها حول الأشخاص الذين يستخدمون منصتنا،
        والتي تشمل:
      </h5>
      <ul>
        <li>
          <p>
            المعلومات الشخصية: تشمل هذه المعلومات الاسم والبريد الإلكتروني ورقم
            الجوال وعنوان المنزل. يتم جمع هذه المعلومات عندما ترسل إلينا طلب
            التواصل أو الاستفسار أو تسجيل حساب لطلب الخدمة.
          </p>
        </li>
        <li>
          <p>
            معلومات التصفح: يتم جمع المعلومات المتعلقة بتصفحك لمنصتنا، والتي
            تشمل على سبيل المثال لا الحصر: عنوان بروتوكول الإنترنت (IP)، نوع
            المتصفح، نظام التشغيل، وما إلى ذلك.
          </p>
        </li>
        <li>
          <p>
            معلومات الدفع: إذا قمت بإجراء معاملة على منصتنا، فسنقوم بجمع بعض
            المعلومات المالية الخاصة بك، مثل رقم بطاقة الائتمان أو المعلومات
            المتعلقة بالحسابات البنكية.
          </p>
        </li>
      </ul>

      <h5>
        كيفية استخدامنا للمعلومات:
        <br />
        <br />
        نستخدم المعلومات التي نجمعها لعدة أغراض، بما في ذلك:
      </h5>
      <ul>
        <li>
          <p>
            تقديم خدماتنا: نستخدم المعلومات التي نجمعها لتوفير الخدمات التي
            نقدمها على منصتنا، بما في ذلك تلبية الطلبات والاستفسارات، وتسجيل
            الحسابات، وتسهيل الدفع وإدارة المعاملات المالية.
          </p>
        </li>
        <li>
          <p>
            تحسين منصتنا: نستخدم المعلومات التي نجمعها لتحسين منصتنا وخدماتنا.
            ونستخدم معلومات التصفح لفهم كيفية استخدام الأشخاص لمنصتنا، وتحسين
            تجربة المستخدم وجعلها أفضل.
          </p>
        </li>
        <li>
          <p>
            التسويق والإعلانات: نستخدم المعلومات التي نجمعها لإرسال الرسائل
            التسويقية والإعلانات عن خدماتنا. ويمكننا أيضًا استخدام المعلومات
            المتعلقة بتصفح المستخدمين لمنصتنا لتحديد الإعلانات التي يتم عرضها
            لهم.
          </p>
        </li>
        <li>
          <p>
            الامتثال للقوانين واللوائح: يمكننا استخدام المعلومات التي نجمعها
            للامتثال للقوانين واللوائح المعمول بها.
          </p>
        </li>
        <li>
          <p>
            حماية حقوقنا ومصالحنا: نستخدم المعلومات التي نجمعها لحماية حقوقنا
            ومصالحنا، بما في ذلك الدفاع عن أي مطالبات قانونية.
          </p>
        </li>
      </ul>
      <h5>مشاركة المعلومات:</h5>
      <p>
        لا نقوم ببيع أو تأجير المعلومات الشخصية لأطراف أخرى. ومع ذلك، قد نشارك
        بعض المعلومات مع الشركات التي نتعامل معها لتقديم الخدمات المتعلقة
        بالمنصة، مثل خدمات المعالجة المالية والتسليم. ونشارك المعلومات الشخصية
        إذا كنا ملزمين بموجب القانون أو بأمر قضائي.
      </p>
      <h5>الأمان:</h5>
      <p>
        نحن على استعداد لاتخاذ الإجراءات اللازمة لحماية المعلومات الشخصية التي
        نجمعها من الوصول غير المصرح به أو الاستخدام أو الكشف أو التدمير غير
        القانوني. نحن نتبع ممارسات أمان معقولة لحماية المعلومات الشخصية، ولكن لا
        يمكننا ضمان الأمان الكامل للمعلومات الشخصية المتنقلة عبر الإنترنت.
      </p>
      <h5>تحديثات سياسة الخصوصية:</h5>
      <p>
        قد نُحدث سياسة الخصوصية هذه بين الحين والآخر لتعكس تغييرات في تقديم
        الخدمات أو القوانين واللوائح المعمول بها. سنقوم بإعلام المستخدمين بأي
        تحديثات مهمة لسياسة الخصوصية، وسيظل المستخدمون ملزمين بالشروط والأحكام
        الجديدة بمجرد نشرها.
      </p>
      <br />
      <p>
        نأمل أن تكون هذه السياسة الخاصة بخدماتنا القانونية واسعة النطاق قد أوضحت
        كيفية جمعنا واستخدامنا ومشاركتنا للمعلومات الشخصية التي تقدمها لنا. إذا
        كان لديك أي أسئلة أو استفسارات بشأن هذه السياسة، فيرجى التواصل بفريق
        الدعم الخاص بنا.
      </p>
    </ng-container>

    <ng-container *ngIf="contentTitle == 'terms-conditions'">
      <h5>
        شروط وأحكام الحصول على الخدمات القانونية من جمعية عزوة
        <br />
        - يشترط في الحصول على استحقاق الخدمة القانونية:
      </h5>

      <ul>
        <li>
          <p>أن تكون القضية من إحدى القضايا المتاحة في المنصة.</p>
        </li>

        <li>
          <p>أن يكون طالب الخدمة محق في دعواه أي أنه صاحب الحق في النزاع.</p>
        </li>
        <li>
          <p>
            أن تكون طالب الخدمة الشخص ذاته (أحد طرفي القضية وليس وكيل أو مفوض عن
            أحد الطرفين).
          </p>
        </li>
        <li>
          <p>
            يتاح تقديم جميع الخدمات القانونية -عدا خدمة الترافع- في جميع مراحل
            القضية حتى انتهائها.
          </p>
        </li>
        <li>
          <p>
            <ins style="text-decoration: underline !important">يشترط للحصول على خدمة الترافع</ins>
            أن يتم طلبها من الجمعية قبل إقامة الدعوى، وفي حال طلبت المستفيدة أن
            يتم الترافع عنها في قضية سبق رفعها لدى المحكمة فيعود قبول الطلب أو
            رفضه لتقدير إدارة الجمعية.
          </p>
        </li>
        <li>
          <p>
            لاستحقاق
            <ins style="text-decoration: underline !important">
              الخدمة المجانية</ins>
            يشترط أن تكون الحالة المادية لطالب الخدمة لا تمكن من دفع رسوم
            الخدمة، ويلتزم طالب الخدمة بتقديم ما يثبت عجزه عن دفع الرسوم، كأن
            يقوم بإرفاق شهادة موثقه من أي جهة مختصة تبين عدم قدرته المادية (مثل:
            بطاقة الضمان الاجتماعي، صك الإعسار، التقرير المالي من سمه الذي يبين
            التعثر المالي، شهادة بالوضع المادي من جمعية خيرية مرخصة بالمملكة،
            شهادة بعدم الالتحاق بعمل أو انخفاض الأجر من المؤسسة العامة للتأمينات
            الاجتماعية).
          </p>
        </li>

        <li>
          <p>
            يشترط لاستحقاق
            <ins style="text-decoration: underline !important">
              الخدمة المجانية
            </ins>
            كذلك استمرار حالة العجز عن دفع رسوم الخدمة طيلة فترة القضية.
          </p>
        </li>

        <li>
          <p>تكون الأولوية لتقديم الخدمة لمن لم يسبق له الحصول على الخدمة.</p>
        </li>

        <li>
          <p>
            <ins style="text-decoration: underline !important">
              التعويض عن الأضرار في الخدمات غير المجانية
            </ins>
            في حال تبين تقصير وإهمال المحامي في خدمة المستفيد بشكل أثر على حقه
            في الدعوى، فتلتزم الجمعية بإعادة كامل الرسوم التي دفعها المستفيد
            للحصول على الخدمة، ويعد ذلك تعويض للمستفيد عن الضرر الحاصل له، ولا
            يستحق المستفيد أي مبالغ مالية أخرى كتعويض إضافي، وبتقديم المستفيد
            لطلب الخدمة والموافقة على هذه الشروط والأحكام يعد موافقاً على قصر
            التعويض عن الضرر بسبب أداء المحامي على إعادة المبالغ المدفوعة فقط.
          </p>
        </li>

        <li>
          <p>
            <ins style="text-decoration: underline !important">
              التعويض عن الأضرار في الخدمات المجانية
            </ins>
            في حال تبين تقصير وإهمال المحامي في خدمة المستفيد بشكل أثر على حقه
            في الدعوى، فتلتزم الجمعية بدفع قيمة رسوم الخدمة المقدمة للمستفيد
            والمبينة في الموقع للمستفيد كتعويض للمستفيد عن الضرر الحاصل له، ولا
            يستحق المستفيد أي مبالغ مالية أخرى كتعويض إضافي، وبتقديم المستفيد
            لطلب الخدمة والموافقة على هذه الشروط والأحكام يعد موافقاً على قصر
            التعويض عن الضرر بسبب أداء المحامي على إعادة المبالغ المدفوعة فقط.
          </p>
        </li>
      </ul>

      <h5>
        مسقطات الخدمة القانونية المقدمة:
        <br />
        - يسقط حق طالب الخدمة القانونية في الحصول عليها وذلك في الحالات الآتية :
      </h5>

      <ul>
        <li>
          <p>
            في حال تبين أن طالب الخدمة لجأ للكذب أو التدليس أو التزوير في إثبات
            حالة العجز عن دفع الأتعاب أو تعثرها المالي وعدم قدرته المادية.
          </p>
        </li>
        <li>
          <p>
            إذا تبين للجمعية بأن الدافع لدى المستفيد لتقديم الطلب هو الكيد
            والمماطلة.
          </p>
        </li>
        <li>
          <p>
            عندما يتضح لدى الجمعية بأن طالب الخدمة ليس له حق بالمطالبة قبل رفع
            القضية أو أثناء العمل بالقضية.
          </p>
        </li>
        <li>
          <p>
            في حال تم تحديد رسوم مالية لاستحقاق الخدمة فيسقط حق طالب الخدمة في
            الحصول على الخدمة في حال تأخر عن الدفع لمدة تزيد عن 72 ساعة.
          </p>
        </li>
        <li>
          <p>
            يسقط حق طالب الخدمة في الاستفادة من خدمة الترافع في حال تأخرت عن
            توكيل المحامي لمدة تزيد عن خمسة أيام عمل من تاريخ إشعاره بطلب
            التوكيل، مالم يكن تأخره راجع لاتفاق المستفيد مع المحامي أو لعذر
            مقبول لدى إدارة الجمعية.
          </p>
        </li>
        <li>
          <p>تنتهي تقديم الخدمة بوفاة طالب الخدمة.</p>
        </li>
      </ul>
    </ng-container>

    <ng-container *ngIf="contentTitle == 'questions'">
      <h5>هل جمعية عزوة موثوقة ورسمية؟</h5>
      <p>
        جمعية عزوة رسمية مسجلة لدى المركز الوطني لتنمية القطاع غير الربحي برقم
        (2232) وتاريخ 01/07/1443هـ، و تحت اشراف فني من وزارة العدل . وتتبع
        المنصة أعلى معايير الشفافية في الممارسات الإدارية والماليـة، وتطبق أعلـى
        المعايير التقنية في أمـن المعلومات.
      </p>

      <h5>
        ماهي الخدمات التي تقدمها عزوة ؟
        <br />
        - تقدم منصة عزوة الخدمات القانونية التالية :
      </h5>
      <ul>
        <li>
          <p>
            <span> خدمات الترافع : </span>
            خدمة يتم خلالها ترشيح محامي مرخص للترافع عن المستفيد أمام المحكمة
            المختصة في الدعوى القضائية لحين صدور الحكم.
          </p>
        </li>
        <li>
          <p>
            <span> خدمات الاستشارات القانونية: </span>
            خدمة يتم خلالها الإجابة على استفسارات المستفيد وتقديم المعلومات
            القانونية له مع إعطائه التوصيات والنصائح الهامة له.
          </p>
        </li>
        <li>
          <p>
            <span> الخدمات القانونية الكتابية والإجرائية: </span>
            خدمة يتم خلالها ربط المستفيد بمختص قانوني لكتابة المذكرات القانونية
            أو لإتمام الإجراءات التقنية المطلوبة في القضية الخاصة به، والمتعلقة
            بالتقاضي أو التنفيذ أو التوثيق وذلك عبر بوابة ناجز التابعة لوزارة
            العدل.
          </p>
        </li>
      </ul>

      <h5>هل الجمعية فقط تدعم النساء ؟</h5>
      <p>تقدم الجمعية الخدمات لكل الجنسين نساء و رجال</p>

      <h5>هل تقبل الجمعية خدمة الغير سعودي ؟</h5>
      <p>
        الجمعية تقدم جميع خدماتها للجميع السعودي و الغير سعودي ( المقيم ) على
        ارض الوطن
      </p>

      <h5>كيف سيتم التعامل مع البيانات المسجلة في المنصة ؟</h5>
      <p>
        تلتزم الجمعية وتطبيقاتها بعدم إفشاء أي بيانات أو وثائق تتعلق بالتقديم ،
        سواءً كانت معلومات شخصية أو مالية، وعدم استغلالها أو الإفصاح عنها.
      </p>

      <h5>هل خدمات جمعية عزوة مجانية ؟</h5>
      <p>
        الترافع مجاني للحالات التي ليس لديها دخل او الدخل الضعيف او منسوبي
        الضمان الاجتماعي فيما عدا ذلك تقدم الخدمة مقابل رسوم رمزية
      </p>

      <h5>من هم الفئة التي تستحق الاعفاء</h5>
      <ul>
        <li>
          <p>اصحاب الضمان الاجتماعي.</p>
        </li>
        <li>
          <p>العاطلين عن العمل</p>
        </li>
        <li>
          <p>العاملين ذو الدخل الضعيف</p>
        </li>
      </ul>

      <h5>ماهي المستندات التي تقدم لقبول الاعفاء عن الرسوم ؟
        <br />
        - إرفاق ما يؤكد استحقاقك للإعفاء من الدفع حتى يتم قبول طلبك. حيث يمكنك
        إرفاق أي مما يلي:
      </h5>

      <ul>
        <li>
          <p>شهادة الالتحاق بالضمان الاجتماعي (اذا كنت من منسوبي الضمان )</p>
        </li>
        <li>
          <p>
            <span> أو </span>
            شهادة الأجور من موقع مؤسسة التأمينات الاجتماعية والتي تبين انخفاض
            الأجر.
          </p>
        </li>
        <li>
          <p>
            <span> أو </span>
            ان كنت لا تعمل فيجب إرفاق حالة الاشتراك من موقع التأمينات الاجتماعية
            من خلال
            <a href="https://www.gosi.gov.sa/GOSIOnline/Check_Employment_Status&loc" target="_blank">الدخول على
              الرابط</a>
            ثم ادخل رقم الهوية و الرمز المرئي ثم الضغط على خيار (تحقق) ثم قم
            بأخذ صورة لحالة الاشتراك وارفقها بمنصة عزوة.
          </p>
        </li>
        <li>
          <p>
            <span> أو </span>
            التقرير المالي الخاص بك من موقع أو تطبيق (ملم) في الروابط أدناه،
            علماً بأن الهدف من التقرير هو توضيح سجلك الائتماني من ناحية القروض
            والبطاقات الائتمانية
            <br />
            <a href="https://eservices.molim.sa/ar/login?redirect=%2Fhome" target="_blank">رابط موقع ملم</a>
            ،
            <a href="https://apps.apple.com/sa/app/%D9%85-%D9%84-%D9%85/id919072738?l=ar" target="_blank">رابط تحميل
              التطبيق</a>
            وفي حال عدم إرفاق المطلوب خلال يومي عمل من تاريخ تقديم الطلب؛ فسيتم رفض الطلب.
          </p>
        </li>
      </ul>

      <h5>
        كيف يمكن التقديم الاعفاء ؟
      </h5>
      <ul>
        <li>
          <p>
            بعد الانتهاء من خطوة التسجيل .
          </p>
        </li>
        <li>
          <p>
            اختيار الخدمات والاسعار
          </p>
        </li>
        <li>
          <p>
            اختيار الخدمة المطلوبة ( الاستشارة القانونية - الترافع امام المحكمة - القانونية الكتابية )
          </p>
        </li>
        <li>
          <p>
            اختيار طلب الاعفاء
          </p>
        </li>
        <li>
          <p>
            ارفق ما يثبت عدم القدرة على الدفع ( كما ذكر في الفقرة السابقة المستندات للإعفاء )
          </p>
        </li>
        <li>
          <p>
            تظهر رساله تم تقديم طلب الاعفاء الرجاء الضغط على علامة X لتنتقل الى الاستشارات او الترافع ... و اكمل تعبئة
            البيانات
            الطلب
          </p>
        </li>
        <li>
          <p>
            اكمل واختار متابعة التقديم ومن ثم يظهر لك رقم الطلب بعدها يمكنك متابعة عبر المنصة.
          </p>
        </li>
      </ul>

      <h5>
        كيف يمكن التواصل معي بعد التقديم ؟
      </h5>
      <p>
        ثم تتلقى اتصال من القسم القانوني من الجمعية وابلاغك بعقد اجتماع اون لاين عبر تطبيق ( مايكروسفت تيمز ) Microsoft
        Teams
        ومن ثم المتابعة عبر المنصة حتى انتهاء الخدمة .
      </p>

      <h5>
        هل المحامي يحضر الجلسة بدل مني ؟
      </h5>
      <p>
        نعم , وذلك اذا تم توكيل المحامي المرخص بذلك
      </p>
    </ng-container>
  </div>

  <figure class="page-wrapper_img">
    <img src="../../../../assets/svg/footer-page.svg" [alt]="contentTitle" />
  </figure>
</section>