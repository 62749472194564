import {Subscription} from 'rxjs';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';
import {
  PreviousValue,
  Question,
} from 'src/app/core/interfaces/case-type.interface';
import {CaseTypeService} from '../../services/case-type.service';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-casetype-question',
  templateUrl: './casetype-question.component.html',
  styleUrls: ['./casetype-question.component.scss'],
  viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'ar'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class CasetypeQuestionComponent implements OnInit {
  @Input() question!: Question;
  @Input() previousValue!: PreviousValue;
  @Input() modifyObject!: any;
  @Input() elementModify!: any;
  @Input() form!: NgForm;
  casetypeSub$!: Subscription;
  public screenWidth: any = window.innerWidth;
  value!: unknown; //TODO interface
  checkValue: any[] = []; //TODO interface
  elementRequired: any = [];
  @Output() questionCredibilityEvent = new EventEmitter<any>();
  questionSub$!: Subscription;
  questionCredibility: any;

  constructor(private _caseTypeService: CaseTypeService) {}
  ngOnInit(): void {
    //check if previousValue hold data or not
    this.value =
      this.previousValue?.[this.question.slug] != undefined
        ? this.previousValue?.[this.question.slug]
        : '';

    this.casetypeSub$ = this._caseTypeService.getEvent().subscribe((res) => {
      this.elementRequired = res;
    });

    if (this.question.type == 'boolean') {
      if (this.value === true || this.value === 'true') {
        this.value = 'true';
        this.checkQuestion({value: 'true', id: this.question.id});
      }
      if (this.value === false || this.value === 'false') this.value = 'false';
      this;
    }
    //check if question type then assign value to checkValue array
    if (this.question.type == 'check')
      this.checkValue = (this.value as any) || [];
  }

  //check checkbox array to push or pop any checked or unchecked value
  checkValueHandler(event: any) {
    const isChecked = event.target.getAttribute('aria-checked') == 'true';
    const value = event.target.getAttribute('value');
    if (isChecked) this.checkValue.push(value);
    if (!isChecked) this.checkValue.filter((item) => item != value);
  }

  // remove class modify when user add answer
  checkValueModify() {
    let element = document.querySelectorAll('.modify');
    element[0]?.classList.remove('modify');
    element[1]?.classList.remove('modify');
  }

  // to accept arabic number and rewrite english number
  toEnglishNumber(strNum: any) {
    var ar = '٠١٢٣٤٥٦٧٨٩'.split('');
    var en = '0123456789'.split('');
    strNum = strNum.replace(/[٠١٢٣٤٥٦٧٨٩]/g, (x: any) => en[ar.indexOf(x)]);
    // strNum = strNum.replace(/[^\d]/g, '');
    return strNum;
  }

  validNumber(value: any) {
    this.value = this.toEnglishNumber(value);
    return this.toEnglishNumber(value);
  }

  // check question credibility
  checkQuestion(obj: any) {
    if (obj.value == 'true' && obj.id) {
      this.questionSub$ = this._caseTypeService
        .getQuestionCredibility(obj.id)
        .subscribe((data) => {
          this.questionCredibility = data;
          this.questionCredibilityEvent.emit({
            id: obj.id,
            data: this.questionCredibility,
          });
        });
    } else {
      this.questionCredibility = undefined;
      this.questionCredibilityEvent.emit({
        id: obj.id,
        data: this.questionCredibility,
      });
    }
  }

  hijridate!: any;
  // get date of birth hijri
  getDateHijri(newDate: any) {
    this.hijridate = newDate.hijriDate;
    if (this.modifyObject && this.modifyObject[this.question.slug]) {
      this.checkValueModify();
    }
  }

  ngOnDestroy(): void {
    if (this.casetypeSub$) this.casetypeSub$.unsubscribe();
    if (this.questionSub$) this.questionSub$.unsubscribe();
  }
}
