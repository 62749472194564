import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PaginationService {
  constructor() {}
  paginatorEvent = new BehaviorSubject(0);

  //emit pagination value to  event
  emitEvent(value: any) {
    this.paginatorEvent.next(value);
  }

  //get value to subscribe to
  getEvent() {
    return this.paginatorEvent;
  }
}
