<!-- list type  -->
<div *ngIf="question.type == 'list'" class="question question-list">
  <label class="question__label" [class.modify]="
      (modifyObject && modifyObject[question.slug]) ||
      elementRequired[0] === question.slug
    ">
    {{ question.title }}
    <span *ngIf="question.isRequired" class="question__label__required">*</span>
  </label>
  <mat-form-field appearance="outline" [class.modify]="
      (modifyObject && modifyObject[question.slug]) ||
      elementRequired[0] === question.slug
    ">
    <mat-select placeholder="اختر" [ngModel]="value" [name]="question.slug" [required]="question.isRequired"
      #warining="ngModel" (change)="
        modifyObject && modifyObject[question.slug] ? checkValueModify() : ''
      ">
      <mat-option *ngFor="let option of question.list" [value]="option.value">{{
        option.value
        }}</mat-option>
    </mat-select>
  </mat-form-field>
  <small class="question__alert-message" *ngIf="
      (!warining?.valid && warining.touched && question.isRequired) ||
      elementRequired[0] === question.slug
    ">هذا الحقل مطلوب</small>
</div>

<!-- string type  -->
<div *ngIf="question.type == 'string'" class="question question-string">
  <label class="question__label" [class.modify]="
      (modifyObject && modifyObject[question.slug]) ||
      elementRequired[0] === question.slug
    ">
    {{ question.title }}
    <span *ngIf="question.isRequired" class="question__label__required">*</span>
  </label>
  <mat-form-field appearance="outline" [class.modify]="
      (modifyObject && modifyObject[question.slug]) ||
      elementRequired[0] === question.slug
    ">
    <input (change)="
        modifyObject && modifyObject[question.slug] ? checkValueModify() : ''
      " class="warning-border" matInput [ngModel]="value" [name]="question.slug" [required]="question.isRequired"
      placeholder="الاجابه" #warining="ngModel"
      pattern="[/\n/\sa-zA-Z\u0660-\u0669\u06F0-\u06F9\d ٌ؛ء-ي’، ً ّ َ ُ؟‘ ِ ٍ~ْ\u0020-\u007e\u00a0-\u00ff\u0152\u0153\u0178]+$"
      (keypress)="validNumber(this.warining.value)" (change)="validNumber(this.warining.value)"
      (input)="validNumber(this.warining.value)" />
  </mat-form-field>
  <small class="question__alert-message" *ngIf="
      (!warining?.valid &&
        warining.touched &&
        question.isRequired &&
        warining.errors &&
        warining.errors['required']) ||
      elementRequired[0] === question.slug
    ">هذا الحقل مطلوب</small>
  <small class="question__alert-message" *ngIf="warining.errors && warining.touched && warining.errors['pattern']">لا
    يمكن ادخال حروف مميزة</small>
  <small class="question__alert-message" *ngIf="modifyObject && modifyObject[question.slug]"><mat-icon
      aria-hidden="false" aria-label="info Add icon" class="material-icons-outlined">info</mat-icon>
    {{ modifyObject[question.slug] }}</small>
</div>

<!-- number type  -->
<div *ngIf="question.type == 'number'" class="question question-number">
  <label class="question__label" [class.modify]="
      (modifyObject && modifyObject[question.slug]) ||
      elementRequired[0] === question.slug
    ">
    {{ question.title }}
    <span *ngIf="question.isRequired" class="question__label__required">*</span>
  </label>
  <mat-form-field [class.modify]="
      (modifyObject && modifyObject[question.slug]) ||
      elementRequired[0] === question.slug
    " appearance="outline">
    <input (change)="
        modifyObject && modifyObject[question.slug] ? checkValueModify() : ''
      " matInput [ngModel]="value" [required]="question.isRequired" [name]="question.slug" placeholder="الاجابه"
      #warining="ngModel" type="text" pattern="^[\u0660-\u0669\u06F0-\u06F9\d]+$"
      onkeypress="return /[\u0660-\u0669\u06F0-\u06F9\d]/i.test(event.key)"
      (keypress)="validNumber(this.warining.value)" (change)="validNumber(this.warining.value)"
      (input)="validNumber(this.warining.value)" />
  </mat-form-field>
  <small class="question__alert-message" *ngIf="
      (!warining?.valid &&
        warining.touched &&
        question.isRequired &&
        warining.errors &&
        warining.errors['required']) ||
      elementRequired[0] === question.slug
    ">هذا الحقل مطلوب</small>
  <small class="question__alert-message" *ngIf="warining.errors && warining.touched && warining.errors['pattern']">لا
    يمكن ادخال حروف مميزة</small>
  <small class="question__alert-message" *ngIf="modifyObject && modifyObject[question.slug]">
    <mat-icon aria-hidden="false" aria-label="info Add icon" class="material-icons-outlined">info</mat-icon>
    {{ modifyObject[question.slug] }}</small>
</div>

<!-- radio type  -->
<div *ngIf="question.type == 'radio'" class="question question-radio">
  <label class="question__label" [class.modify]="
      (modifyObject && modifyObject[question.slug]) ||
      elementRequired[0] === question.slug
    ">
    {{ question.title }}
    <span *ngIf="question.isRequired" class="question__label__required">*</span>
  </label>
  <mat-radio-group [class.modify]="
      (modifyObject && modifyObject[question.slug]) ||
      elementRequired[0] === question.slug
    " [ngModel]="value" [required]="question.isRequired" [name]="question.slug" #warining="ngModel">
    <mat-radio-button (change)="
        modifyObject && modifyObject[question.slug] ? checkValueModify() : ''
      " *ngFor="let option of question.list" [value]="option.value">{{ option.value }}</mat-radio-button>
  </mat-radio-group>
  <small class="question__alert-message" *ngIf="
      (!warining?.valid && warining.touched && question.isRequired) ||
      elementRequired[0] === question.slug
    ">هذا الحقل مطلوب</small>
</div>

<!-- boolean type  -->
<div *ngIf="question.type == 'boolean'" class="question question-boolean">
  <label class="question__label" [class.modify]="
      (modifyObject && modifyObject[question.slug]) ||
      elementRequired[0] === question.slug
    ">
    {{ question.title }}
    <span *ngIf="question.isRequired" class="question__label__required">*</span>
  </label>
  <mat-radio-group [class.modify]="
      (modifyObject && modifyObject[question.slug]) ||
      elementRequired[0] === question.slug
    " (change)="
      modifyObject && modifyObject[question.slug] ? checkValueModify() : ''
    " [required]="question.isRequired" [ngModel]="value" [name]="question.slug" #warining="ngModel">
    <mat-radio-button value="true" (change)="checkQuestion({value: 'true', id: question.id})">نعم</mat-radio-button>
    <mat-radio-button value="false" (change)="checkQuestion({value: 'false', id: question.id})">لا</mat-radio-button>
  </mat-radio-group>
  <small class="question__alert-message" *ngIf="
      (!warining?.valid && warining.touched && question.isRequired) ||
      elementRequired[0] === question.slug
    ">هذا الحقل مطلوب</small>
</div>

<!-- check type  -->
<div *ngIf="question.type == 'check'" class="question question-check">
  <label class="question__label" [class.modify]="
      (modifyObject && modifyObject[question.slug]) ||
      elementRequired[0] === question.slug
    ">
    {{ question.title }}
    <span *ngIf="question.isRequired" class="question__label__required">*</span>
  </label>
  <!-- input doesn't render ... it's just for hold checkbox array value and pass to the form -->
  <input [class.modify]="
      (modifyObject && modifyObject[question.slug]) ||
      elementRequired[0] === question.slug
    " (change)="
      modifyObject && modifyObject[question.slug] ? checkValueModify() : ''
    " type="text" [ngModel]="checkValue" [required]="checkValue.length == 0 && question.isRequired"
    [name]="question.slug" #warining="ngModel" class="ng-invalid" [ngStyle]="{display: 'none'}" />
  <!-- and listen to every checkbox is checked or not to push or pop it from array -->
  <mat-checkbox *ngFor="let option of question.list" [value]="option.value" (input)="checkValueHandler($event)"
    [checked]="checkValue.includes(option.value)">{{ option.value }}</mat-checkbox>
  <small class="question__alert-message" *ngIf="
      (!warining?.valid && warining.touched && question.isRequired) ||
      elementRequired[0] === question.slug
    ">هذا الحقل مطلوب</small>
</div>

<!-- date type  -->
<div *ngIf="question.type == 'calendar'" class="question question-calendar">
  <label class="question__label" [class.modify]="
      (modifyObject && modifyObject[question.slug]) ||
      elementRequired[0] === question.slug
    ">
    {{ question.title }}
    <span *ngIf="question.isRequired" class="question__label__required">*</span>
  </label>

  <div *ngIf="question.calendarType == 'gregorian'">
    <mat-form-field class="bodytext-normal full-width" appearance="outline" [class.modify]="
        (modifyObject && modifyObject[question.slug]) ||
        elementRequired[0] === question.slug
      ">
      <input class="date" (change)="
          modifyObject && modifyObject[question.slug] ? checkValueModify() : ''
        " matInput [matDatepicker]="datePicker1" autocomplete="off" (click)="datePicker1.open()" readonly
        [ngModel]="value" [required]="question.isRequired" [name]="question.slug" #warining="ngModel" />
      <mat-datepicker-toggle class="custom-suffix" matSuffix [for]="datePicker1"></mat-datepicker-toggle>
      <mat-datepicker #datePicker1> </mat-datepicker>
    </mat-form-field>
    <small class="question__alert-message" *ngIf="
        (!warining?.valid && warining.touched && question.isRequired) ||
        elementRequired[0] === question.slug
      ">هذا الحقل مطلوب</small>
  </div>

  <app-hijri-calender *ngIf="question.calendarType == 'hijri'" [classModify]="
      (modifyObject && modifyObject[question.slug]) ||
      elementRequired[0] === question.slug
    " (newItemEvent)="getDateHijri($event)" [value]="value | hijriDate"
    [required]="question.isRequired"></app-hijri-calender>
  <input *ngIf="question.calendarType == 'hijri'" class="date" [ngModel]="this.hijridate ? this.hijridate : value "
    [required]="question.isRequired" [name]="question.slug" (change)="
      modifyObject && modifyObject[question.slug] ? checkValueModify() : ''
    " style="
      visibility: hidden;
      position: absolute;
      pointer-events: none;
      height: 0;
    " />
  <small class="question__alert-message" *ngIf="modifyObject && modifyObject[question.slug]">

    <mat-icon aria-hidden="false" aria-label="info Add icon" class="material-icons-outlined">info</mat-icon>
    {{ modifyObject[question.slug] }}</small>
</div>