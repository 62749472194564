import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {CasesService} from '../../service/cases.service';

@Component({
  selector: 'app-answer-complain-dialog',
  templateUrl: './answer-complain-dialog.component.html',
  styleUrls: ['./answer-complain-dialog.component.scss'],
})
export class AnswerComplainDialogComponent implements OnInit {
  complainData!: any;
  complainSub$!: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _casesService: CasesService,
  ) {
    // get complain id
    this.complainSub$ = this._casesService
      .getComplainById(this.data.complainId)
      .subscribe({
        next: (data) => {
          this.complainData = data;
        },
      });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    if (this.complainSub$) this.complainSub$.unsubscribe();
  }
}
