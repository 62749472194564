import { EventEmitter, Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LatestUpdatesService {
  constructor() {}

  //create latest update event
  latestUpdate = new EventEmitter();

  //emit latest update value to latest update event
  emitEvent(value: any) {
    this.latestUpdate.emit(value);
  }

  //get event emitter to subscribe to
  getEvent() {
    return this.latestUpdate;
  }

  //create new Replay event
  addNewReplay = new EventEmitter();

  //emit new Replay value to latest update event
  emitReplayEvent(value: any) {
    this.addNewReplay.emit(value);
  }

  //get event emitter to subscribe to
  getReplayEvent() {
    return this.addNewReplay;
  }

  // comment Load

  //create comment Load event
  replayLoad = new Subject();

  //emit comment Load form to  event
  emitEventLoad(value: any) {
    this.replayLoad.next(value);
  }

  //get event emitter comment Load to subscribe to
  getEventLoad() {
    return this.replayLoad;
  }
}
