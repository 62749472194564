import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PaymentService } from '../../service/payment.service';

@Component({
  selector: 'app-payment-page',
  templateUrl: './payment-page.component.html',
  styleUrls: ['./payment-page.component.scss'],
})
export class PaymentPageComponent {
  @Input() data?: any;

  constructor(private _PaymentService: PaymentService) { }

  ngOnInit() {
    let PaymentSession: any = (<any>window)['Moyasar'];
    let estimationID: any = this.data.body.id;
    let api = environment.api_url;
    let publishKey = environment.publishable_api_key;
    let token = this._PaymentService.userToken;
    PaymentSession?.init({
      element: '.mysr-form',
      amount: this.data.body.amount * 100,
      currency: 'SAR',
      description: 'جمعية عزوة',
      publishable_api_key: publishKey,
      callback_url: this.data.type === 'subscription' ? `${window.location.href.split("?")[0]}?serviceId=${this.data.body.serviceId}${this.data.body.hasEntity?'&entity=true':''}` : (this.data.type == 'remaining' || this.data.type == 'upgrade') ? `${window.location.href}?paytype=${this.data.type}` : `${window.location.href}`,
      methods: ['creditcard', 'applepay'],
      apple_pay: {
        country: 'SA',
        label: 'Ezoah',
        validate_merchant_url: 'https://api.moyasar.com/v1/applepay/initiate',
      },
      on_completed: (this.data.type === 'subscription' || this.data.type === 'remaining' || this.data.type === 'upgrade') ? `${api}user/subscription-payments/on-complete/${estimationID}` : `${api}user/payments/on-complete/${estimationID}`,
    });
  }
}
