import { EventEmitter, Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CommentService {
  //create comment event
  answerComment = new EventEmitter();

  //emit comment form to  event
  emitEvent(answer: any) {
    this.answerComment.emit(answer);
  }

  //get event emitter to subscribe to
  getEvent() {
    return this.answerComment;
  }

  // comment Load

  //create comment Load event
  commentLoad = new Subject();

  //emit comment Load form to  event
  emitEventLoad(value: any) {
    this.commentLoad.next(value);
  }

  //get event emitter comment Load to subscribe to
  getEventLoad() {
    return this.commentLoad;
  }

  // scroll after comment

  //create scroll after comment event
  scrollAfterComment = new Subject();

  //emit scroll after comment form to  event
  emitEventScroll(value: any) {
    this.scrollAfterComment.next(value);
  }

  //get event emitter scroll after comment to subscribe to
  getEventScroll() {
    return this.scrollAfterComment;
  }
}
