import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-check-login-dialog',
  templateUrl: './check-login-dialog.component.html',
  styleUrls: ['./check-login-dialog.component.scss']
})
export class CheckLoginDialogComponent implements OnInit {

  constructor(private _router:Router , private _dialogRef: MatDialogRef<CheckLoginDialogComponent> , @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  //redirect to login
  goToLogin(){
    this._router.navigateByUrl("/user/login");
    this._dialogRef.close();
  }

  //redirect to register
  goToRegister(){
    this._router.navigateByUrl("/user/register");
    this._dialogRef.close();
  }

}
