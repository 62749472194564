import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UserService} from '@kortobaa-front/authentication';
import {Observable} from 'rxjs/internal/Observable';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PromoCodeValidationService {
  private promoCode:  string | null = null;
  constructor(private _http: HttpClient, private _user: UserService) {}

  userToken() {
    return {
      headers: {Authorization: `Bearer ${this._user.getToken()}`},
    };
  }

  checkPromoCodeValidation(promoCode: string): Observable<any> {
    return this._http.post(
      `${environment.api_url}user/coupons/validate`,
      {
        "title" : promoCode
    },
      this.userToken(),
    );
  }
  
  checkAutoDiscountAvailability(){
    // TODO
    // return this._http.post(
    //   `${environment.api_url}`,
    //   this.userToken(),
    // );
  }
}
