<div class="terms-dialog">
  <div mat-dialog-actions class="terms-dialog__buttons">
    <mat-icon mat-dialog-close aria-hidden="false" aria-label="Example close icon"
      class="material-icons-outlined terms-dialog__buttons__close">close</mat-icon>
    <h2>
      الشروط و الأحكام
    </h2>
  </div>
  <div class="terms-dialog__info">
    <h5>
      شروط وأحكام الحصول على الخدمات القانونية من جمعية عزوة
      <br />
      - يشترط في الحصول على استحقاق الخدمة القانونية:
    </h5>
    <ul>
      <li>
        <p>أن تكون القضية من إحدى القضايا المتاحة في المنصة.</p>
      </li>

      <li>
        <p> أن يكون طالب الخدمة محق في دعواه أي أنه صاحب الحق في النزاع.</p>
      </li>
      <li>
        <p>
          أن تكون طالب الخدمة الشخص ذاته (أحد طرفي القضية وليس وكيل أو مفوض عن أحد الطرفين).
        </p>
      </li>
      <li>
        <p>
          يتاح تقديم جميع الخدمات القانونية -عدا خدمة الترافع- في جميع مراحل
          القضية حتى انتهائها.
        </p>
      </li>
      <li>
        <p>
          <ins style="text-decoration: underline !important">يشترط للحصول على خدمة الترافع</ins>
          أن يتم طلبها من الجمعية قبل إقامة الدعوى، وفي حال طلبت المستفيدة أن
          يتم الترافع عنها في قضية سبق رفعها لدى المحكمة فيعود قبول الطلب أو
          رفضه لتقدير إدارة الجمعية.
        </p>
      </li>
      <li>
        <p>
          لاستحقاق
          <ins style="text-decoration: underline !important">
            الخدمة المجانية</ins>
          يشترط أن تكون الحالة المادية لطالب الخدمة لا تمكن من دفع رسوم الخدمة، ويلتزم طالب الخدمة بتقديم ما يثبت عجزه
          عن دفع
          الرسوم، كأن يقوم بإرفاق شهادة موثقه من أي جهة مختصة تبين عدم قدرته المادية (مثل: بطاقة الضمان الاجتماعي، صك
          الإعسار،
          التقرير المالي من سمه الذي يبين التعثر المالي، شهادة بالوضع المادي من جمعية خيرية مرخصة بالمملكة، شهادة بعدم
          الالتحاق
          بعمل أو انخفاض الأجر من المؤسسة العامة للتأمينات الاجتماعية).
        </p>
      </li>

      <li>
        <p>
          يشترط لاستحقاق
          <ins style="text-decoration: underline !important">
            الخدمة المجانية
          </ins>
          كذلك استمرار حالة العجز عن دفع رسوم الخدمة طيلة فترة القضية.
        </p>
      </li>

      <li>
        <p>
          تكون الأولوية لتقديم الخدمة لمن لم يسبق له الحصول على الخدمة. </p>
      </li>

      <li>
        <p>
          <ins style="text-decoration: underline !important">
            التعويض عن الأضرار في الخدمات غير المجانية </ins>
          في حال تبين تقصير وإهمال المحامي في خدمة المستفيد بشكل أثر على حقه في الدعوى، فتلتزم الجمعية بإعادة كامل
          الرسوم التي
          دفعها المستفيد للحصول على الخدمة، ويعد ذلك تعويض للمستفيد عن الضرر الحاصل له، ولا يستحق المستفيد أي مبالغ
          مالية أخرى
          كتعويض إضافي، وبتقديم المستفيد لطلب الخدمة والموافقة على هذه الشروط والأحكام يعد موافقاً على قصر التعويض عن
          الضرر بسبب
          أداء المحامي على إعادة المبالغ المدفوعة فقط.
        </p>
      </li>

      <li>
        <p>
          <ins style="text-decoration: underline !important">
            التعويض عن الأضرار في الخدمات المجانية
          </ins>
          في حال تبين تقصير وإهمال المحامي في خدمة المستفيد بشكل أثر على حقه في الدعوى، فتلتزم الجمعية بدفع قيمة رسوم
          الخدمة
          المقدمة للمستفيد والمبينة في الموقع للمستفيد كتعويض للمستفيد عن الضرر الحاصل له، ولا يستحق المستفيد أي مبالغ
          مالية أخرى
          كتعويض إضافي، وبتقديم المستفيد لطلب الخدمة والموافقة على هذه الشروط والأحكام يعد موافقاً على قصر التعويض عن
          الضرر بسبب
          أداء المحامي على إعادة المبالغ المدفوعة فقط.
        </p>
      </li>
    </ul>

    <h5>
      مسقطات الخدمة القانونية المقدمة:
      <br />
      - يسقط حق طالب الخدمة القانونية في الحصول عليها وذلك في الحالات الآتية :
    </h5>

    <ul>
      <li>
        <p>
          في حال تبين أن طالب الخدمة لجأ للكذب أو التدليس أو التزوير في إثبات حالة العجز عن دفع الأتعاب أو تعثرها
          المالي وعدم
          قدرته المادية. </p>
      </li>
      <li>
        <p>
          إذا تبين للجمعية بأن الدافع لدى المستفيد لتقديم الطلب هو الكيد والمماطلة.
        </p>
      </li>
      <li>
        <p>
          عندما يتضح لدى الجمعية بأن طالب الخدمة ليس له حق بالمطالبة قبل رفع القضية أو أثناء العمل بالقضية.
        </p>
      </li>
      <li>
        <p>
          في حال تم تحديد رسوم مالية لاستحقاق الخدمة فيسقط حق طالب الخدمة في
          الحصول على الخدمة في حال تأخر عن الدفع لمدة تزيد عن 72 ساعة.
        </p>
      </li>
      <li>
        <p>
          يسقط حق طالب الخدمة في الاستفادة من خدمة الترافع في حال تأخرت عن توكيل المحامي لمدة تزيد عن خمسة أيام عمل
          من تاريخ
          إشعاره بطلب التوكيل، مالم يكن تأخره راجع لاتفاق المستفيد مع المحامي أو لعذر مقبول لدى إدارة الجمعية.
        </p>
      </li>
      <li>
        <p>تنتهي تقديم الخدمة بوفاة طالب الخدمة.</p>
      </li>
    </ul>
  </div>
</div>