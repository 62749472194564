import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {ServicesListService} from 'src/app/case-type/services/services-list.service';
import {MainService} from 'src/app/core/interfaces/main-service';
import {AuthService} from 'src/app/core/services/auth.service';
import {PaymentService} from 'src/app/payment/service/payment.service';
import {MessageDialogComponent} from 'src/app/shared/dialogs/message-dialog/message-dialog.component';
import {SnapPixelService} from 'src/app/shared/services/snap-pixel.service';
import {FooterService} from 'src/app/ui/service/footer.service';
import {NavbarService} from 'src/app/ui/service/navbar.service';

@Component({
  selector: 'app-services-page',
  templateUrl: './services-page.component.html',
  styleUrls: ['./services-page.component.scss'],
})
export class ServicesPageComponent implements OnInit {
  servicesListSub$!: Subscription;
  mainServices: MainService[] | null = null;
  subRoutSub$!: Subscription;
  paymentSub$!: Subscription;
  spinnerShow: boolean = false;

  constructor(
    private _router: Router,
    private _navbar: NavbarService,
    private _footer: FooterService,
    private _ServicesListService: ServicesListService,
    private _activeRoute: ActivatedRoute,
    private _paymentService: PaymentService,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _snapPixelService: SnapPixelService,
    private _authService: AuthService,
  ) {
    //set navbar to visible
    this._navbar.setNavbarVisiabilty(true);
    this._footer.setFooterVisibility(true);
    //get all main services
    this.servicesListSub$ = this._ServicesListService
      .getMainServices()
      .subscribe({
        next: (data) => {
          this.mainServices = data;
          if (this._authService.user?.currentUser?.email && this.mainServices) {
            this._snapPixelService.loadScript(
              this._authService.user.currentUser.email,
              'PAGE_VIEW',
              this.mainServices,
            );
          }
        },
        complete: () => this.addPayment(),
      });
  }

  ngOnInit(): void {}

  addPayment() {
    this.subRoutSub$ = this._activeRoute.queryParamMap.subscribe(
      (data: any) => {
        if (data.params.id && data.params.status && data.params.serviceId) {
          this.spinnerShow = true;
          const service = this.mainServices?.find(
            (item) => item.id === +data.params.serviceId,
          );
          this._snapPixelService.removeScript();
          this.paymentSub$ = this._paymentService
            .validSubscriptionPaymentID({
              paymentId: data.params.id,
            })
            .subscribe({
              next: (resData: any) => {
                this.spinnerShow = false;
                // snap pixel
                this._snapPixelService.loadScript(
                  this._authService.user.currentUser.email,
                  'PURCHASE',
                  {
                    ...{
                      ...this.mainServices?.find((service: any) => {
                        return service.id == data.params.serviceId;
                      }),
                    },
                    transaction_id: data.params.id,
                    item_category: data.params.serviceId,
                  },
                );
                const dialogRef = this._dialog.open(MessageDialogComponent, {
                  panelClass: 'payment-dialog-success',
                  width: 'auto',
                  maxWidth: '90vw',
                  // height: '39rem',
                  data: {
                    flag: 'payment',
                    type: 'paymentSubscription',
                    message: `تم دفع تكلفة الخدمة ${service?.title} بنجاح`,
                  },
                });
                dialogRef.afterClosed().subscribe((x) => {
                  this._router.navigate(
                    [
                      this.mainServices?.find(
                        (item) => item.id === +data.params.serviceId,
                      )?.type === 'case'
                        ? '/casetype'
                        : '/inquiry/create',
                    ],
                    {queryParams: {serviceId: +data.params.serviceId}},
                  );
                  this._snapPixelService.removeScript();
                });
              },
              error: (errData: any) => {
                this.spinnerShow = false;
                this._snackBar
                  .open('فشل في عملية الدفع', 'X', {
                    panelClass: 'error-snackbar',
                  })
                  .afterDismissed()
                  .subscribe((x) => {
                    this._router.navigateByUrl(`/services`);
                  });
              },
            });
        }
      },
    );
  }

  ngOnDestroy(): void {
    if (this.servicesListSub$) this.servicesListSub$.unsubscribe();
    if (this.subRoutSub$) this.subRoutSub$.unsubscribe();
    if (this.paymentSub$) this.paymentSub$.unsubscribe();
    // remove snap pixel
    this._snapPixelService.removeScript();
    sessionStorage.removeItem('plan');
  }
}
