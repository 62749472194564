import {Component, Input, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ServicesListService} from 'src/app/case-type/services/services-list.service';
import {MainService} from 'src/app/core/interfaces/main-service';
import {ScrollService} from '../../service/scroll.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-services-list',
  templateUrl: './services-list.component.html',
  styleUrls: ['./services-list.component.scss'],
})
export class ServicesListComponent implements OnInit {
  @Input() mainServices: MainService[] | null = null;
  subRoute$!: Subscription;
  constructor(
    private _ScrollService: ScrollService,
  ) { }

  ngOnInit(): void {}

  scrollToPlan(serviceId: string) {
    this._ScrollService.sendScrollEvent(`_${serviceId}`);
  }
}
